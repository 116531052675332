import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { useStateValue } from '../state';

const VideoContainer = styled.div`
  margin: 0 auto;
  position: relative;
`;

const FullScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  div {
    width: 100vw;
    height: 100vh;
    video {
      width: 100%;
    }
  }
`;

const CloseButtonStyle = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: black;
  border: 3px solid #eee;
  padding: 10px;
  svg {
    polygon {
      fill: #eee;
    }
  }
  transition: 150ms;
  &:hover {
    /* transform: rotate(90deg); */
    scale: 1.1;
  }
`;

// Using state instead of defaults
// const defaults = {
//   playing: true,
// };

function VideoPlayer({ url, ...props }) {
  const [full, setFull] = useState(true);
  const [{ fullScreenRef, feedback }] = useStateValue();
  const [playing, setPlaying] = useState(true);
  console.log({ fullScreenRef, feedback });
  if (!fullScreenRef?.current || !full) {
    console.log({ props });
    return (
      <VideoContainer>
        {!feedback && (
          <ReactPlayer
            url={url}
            controls
            width="100%"
            height="100%"
            playing={playing}
            // onEnded={() => setFull(false)}
            // {...defaults}
            {...props}
          />
        )}
      </VideoContainer>
    );
  }
  return createPortal(
    <FullScreenContainer isPortal>
      <ReactPlayer
        url={url}
        controls
        width="100%"
        height="100%"
        onEnded={() => {
          setFull(false);
          setPlaying(false);
        }}
        playing={playing}
        // {...defaults}
        {...props}
      />
      <CloseButtonStyle
        onClick={() => {
          setFull(false);
          setPlaying(false);
        }}
        onKeyPress={() => setFull(false)}
        tabIndex={0}
        type="button"
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 96 96"
          enable-background="new 0 0 96 96"
        >
          <polygon
            fill="#AAAAAB"
            points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "
          />
        </svg>
      </CloseButtonStyle>
    </FullScreenContainer>,
    fullScreenRef.current,
  );
}

export default VideoPlayer;
