import { evokedsetBasic } from './brandConfigs/evokedsetBasic';
import { telenorDark, telenorLight, telenorYng } from './brandConfigs/telenor';
import { hurtigruten, hurtigrutenGroup, hurtigrutenExpedition } from './brandConfigs/hurtigruten';
import { volmax } from './brandConfigs/volmax';

/* we export all the themes as key7values in a single object */

export const brandThemes = {
  evokedsetBasic,
  telenorDark,
  telenorLight,
  telenorYng,
  hurtigruten,
  hurtigrutenGroup,
  hurtigrutenExpedition,
  volmax,
};
