import React, { useEffect } from 'react';
import { bool, string } from 'prop-types';
import * as Yup from 'yup';
import { Card, Stack } from '@sanity/ui';
import styled, { withTheme } from 'styled-components';
import { TextInput, Checkbox, TextArea } from './Inputs';
import AlternativeButton from '../AlternativeButton';
import { useStateValue } from '../../state';
import { useState } from 'reinspect';
// import FormGenerator from './FormGenerator';

// TODO: Consider refactoring

// ************************************************************

// // An example
// const FormComponents = {
//   'hr-omvendt': HRForm,
//   'hr-textfield': HRTextFieldForm,
//   'telenor-textinput': TelenorTextInputForm,
//   //...
// };

// function ConfiguredForm(/* props */) {
//   //...
//   const FormComponent = FormComponents[altForm || formId];
//   if (!FormComponent) {
//     // handle this case as you wish
//   }

//   return <FormComponent /* your props */ />;
// }

// *********************************************************

const StyledStack = styled(Stack)`
  ${p => p.theme?.sanity?.brand?.form}

  .christmasStyle {
    margin: 1em -2em;
    padding: 2em;
    background: #0c0f27
      url(https://cdn.sanity.io/images/4kn9ntmr/production/70c455a8be62c334669e1bcbde1c0ac81e2917bd-1000x136.png)
      left bottom no-repeat;
    color: white;
    background-size: contain;
    padding-bottom: 7em;
    padding-top: 1px;
  }

  @media (max-width: 500px) {
    .christmasStyle {
      margin: 1em -24px;
      padding: 24px;
      padding-bottom: 6em;
      padding-top: 1px;
    }
  }
`;

// Import validation schema values
// if validation schema is a _function_ it can receive a language and export the correct one.

function ConfiguredForm({ formId, isSubmitting, theme }) {
  const [{ quiz, formKey, themeKey, notCheated, itsChristmas }, dispatch] = useStateValue();

  const [parsedCheckboxLabel, setParsedCheckboxLabel] = useState('');

  // Check if formkey is present
  const altForm = quiz.formKey ? quiz.formKey : '';

  useEffect(() => {
    // Stick the formKey in the global state
    if (formKey === null) {
      dispatch({ type: 'formKey', payload: altForm });
    }

    // This is a (filthy) hack for achieving Christmas look for T-We
    if (quiz._id === '4507f62d-cd17-4c34-a682-78496d2db6b6') {
      dispatch({ type: 'setItsChristmas', payload: true });
    }

    transFormCheckboxlabel();
  }, []);

  const {
    sanity: {
      brand: {
        strings: {
          checkboxLabel: tempCheckBoxLabel,
          submitBtnLabel: tempSubmitBtnLabel,
          submittingBtnLabel: tempSubmittingBtnLabel,
        },
        uiLabels,
      },
    },
  } = theme;

  // If a checkbox label is passed down from the quiz object use that as
  // checkbox-label, otherwise use the one handed down.

  const checkboxLabel =
    quiz?.customUIlabels?.consentMessageText && quiz?.customUIlabels?.consentMessageText.length > 0
      ? quiz?.customUIlabels?.consentMessageText
      : tempCheckBoxLabel;

  // setConsentMessage(checkboxLabel);

  // console.log({ checkboxLabel });
  // If uiLabels exist use them instead in conjunction with language, otherwise use regular string config
  // But, if it's overwritting via CMS, replace it with that.

  // const submittingBtnLabel =
  //   quiz?.customUIlabels?.consentMessageText && quiz?.customUIlabels?.consentMessageText.length > 0
  //     ? quiz?.customUIlabels?.consentMessageText
  //     : tempSubmittingBtnLabel;

  // const submitBtnLabel =
  //   quiz?.customUIlabels?.consentMessageText && quiz?.customUIlabels?.consentMessageText.length > 0
  //     ? quiz?.customUIlabels?.consentMessageText
  //     : tempSubmitBtnLabel;

  function transFormCheckboxlabel() {
    if (checkboxLabel.indexOf('%%') > 0) {
      // Split into three bits
      const splitter = checkboxLabel.split('%%');

      // Split the target too
      const link = splitter[1].split('||');
      const theAHref = () => (
        <p>
          {splitter[0]}
          <a href={link[1]} target="_blank">
            {link[0]}
          </a>
          {splitter[2]}
        </p>
      );
      setParsedCheckboxLabel(theAHref);
    } else {
      setParsedCheckboxLabel(<p>{checkboxLabel}</p>);
    }
  }

  ////////////////////////////////////////////////

  // Check parse the language and use dynamic labels in form

  // Identify the language
  const language = quiz?.language && quiz?.language.length > 0 ? quiz?.language : 'norwegian';

  // Using dynamic labels only on the HR form for now
  const theFormLabels = uiLabels[language]?.form;
  // TODO: What happens if one is missing?

  //////////////////

  // eslint-disable-next-line no-nested-ternary
  const submitBtnLabel = quiz?.customUIlabels?.submitButtonText
    ? quiz?.customUIlabels?.submitButtonText
    : uiLabels[language]?.form?.submit
    ? uiLabels[language]?.form.submit
    : tempSubmitBtnLabel;

  const submittingBtnLabel = uiLabels[language]?.form?.submitting
    ? uiLabels[language]?.form.submitting
    : tempSubmittingBtnLabel;

  // const submitBtnLabel = myPerfectSubmissionMessage;
  // const submittingBtnLabel = myPerfectSubmittingMessage;

  /// //////////////////////////////////////////

  // This is where we add the new custom forms
  // We need a component that takes the form config object plus the parsed checkbox label,
  // isSubmitting, submittingbBtnLabel and submitBtnLabel and renders the form
  // console.log();

  // NOTE: In a previous version I would render the form generator here, but that's not how it's done anymore. Go back to previous iterations 13 June, 2023 if not.

  // I'll also check if I get the altForm. If I do, I'll serve up yet a different form.

  // Here follows a cascade of forms. Alt forms just means that it's not the default form for that client

  // if (altForm && altForm === 'hr-textfield') {
  // Switch this back when you're ready to roll
  if (altForm && altForm === 'hr-omvendt') {
    // Form for omvendtauksjon
    return (
      <StyledStack className="customForm" padding={4}>
        <Card className="customForm">
          <TextInput
            label="Ditt bud (kun hele kroner) "
            name="textInput"
            type="text"
            placeholder=""
          />
        </Card>
        <Card>
          <TextInput label="Fornavn" name="firstName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Etternavn" name="lastName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Telefonnummer" name="phoneNumber" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Postnummer" name="postCode" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="E-post" name="email" type="email" placeholder="" />
        </Card>
        <Card>
          <Checkbox name="acceptedTerms">{parsedCheckboxLabel}</Checkbox>
        </Card>
        <Card>
          <AlternativeButton disabled={isSubmitting} type="submit">
            {isSubmitting ? submittingBtnLabel : submitBtnLabel}
          </AlternativeButton>
        </Card>
      </StyledStack>
    );
  }

  if (altForm && altForm === 'hr-textfield') {
    return (
      <StyledStack className="customForm" padding={4}>
        <Card>
          <TextArea label="Your moment (max 320 characters):" name="textfield" type="textarea" />
        </Card>
        <Card>
          <TextInput label="Name" name="fullName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Phone number" name="phoneNumber" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Email" name="email" type="email" placeholder="" />
        </Card>
        <Card>
          <Checkbox name="acceptedTerms">{parsedCheckboxLabel}</Checkbox>
        </Card>

        <Card>
          <AlternativeButton disabled={isSubmitting} type="submit">
            {isSubmitting ? submittingBtnLabel : submitBtnLabel}
          </AlternativeButton>
        </Card>
      </StyledStack>
    );
  }

  if (altForm && altForm === 'telenor-textinput') {
    return (
      <StyledStack className="customForm" padding={4}>
        <Card className="christmasStyle">
          <TextInput label="Svar på dagens oppgave: " name="textInput" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Navn" name="fullName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Telefonnummer" name="phoneNumber" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="E-post" name="email" type="email" placeholder="" />
        </Card>
        <Card>
          <Checkbox name="acceptedTerms">{parsedCheckboxLabel}</Checkbox>
        </Card>
        <Card>
          <AlternativeButton disabled={isSubmitting} type="submit">
            {isSubmitting ? submittingBtnLabel : submitBtnLabel}
          </AlternativeButton>
        </Card>
      </StyledStack>
    );
  }

  if (formId === 'hurtigruten') {
    return (
      <StyledStack className="customForm" padding={4}>
        <Card>
          <TextInput label={theFormLabels?.name} name="fullName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label={theFormLabels?.phone} name="phoneNumber" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label={theFormLabels?.email} name="email" type="email" placeholder="" />
        </Card>
        <Card>
          {quiz?.showConsentCheckbox && (
            <Card>
              <Checkbox name="acceptedTerms">{parsedCheckboxLabel}</Checkbox>
            </Card>
          )}
        </Card>

        <Card>
          <AlternativeButton disabled={isSubmitting} type="submit">
            {isSubmitting ? submittingBtnLabel : submitBtnLabel}
          </AlternativeButton>
        </Card>
      </StyledStack>
    );
  }
  if (formKey === 'volmax-standard') {
    return (
      <StyledStack className="customForm" padding={4}>
        <Card>
          <TextInput label={theFormLabels?.firstName} name="firstName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label={theFormLabels?.lastName} name="lastName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label={theFormLabels?.phone} name="phoneNumber" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label={theFormLabels?.email} name="email" type="email" placeholder="" />
        </Card>
        <Card>
          {/* {quiz?.showConsentCheckbox && ( */}
          <Card>
            <Checkbox name="acceptedTerms">{parsedCheckboxLabel}</Checkbox>
          </Card>
          {/* )} */}
        </Card>

        <Card>
          <AlternativeButton disabled={isSubmitting} type="submit">
            {isSubmitting ? submittingBtnLabel : submitBtnLabel}
          </AlternativeButton>
        </Card>
      </StyledStack>
    );
  }
  return (
    <StyledStack className="customForm" padding={4}>
      <Card>
        <TextInput label="Navn" name="fullName" type="text" placeholder="" />
      </Card>
      <Card>
        <TextInput label="Telefonnummer" name="phoneNumber" type="text" placeholder="" />
      </Card>
      <Card>
        <TextInput label="E-post" name="email" type="email" placeholder="" />
      </Card>
      <Card>
        <Card>
          <Checkbox name="acceptedTerms">{parsedCheckboxLabel}</Checkbox>
        </Card>
      </Card>
      <Card>
        <AlternativeButton disabled={isSubmitting} type="submit">
          {isSubmitting ? submittingBtnLabel : submitBtnLabel}
        </AlternativeButton>
      </Card>
    </StyledStack>
  );
}

ConfiguredForm.defaultProps = {
  formId: 'telenor',
  isSubmitting: false,
  checkboxLabel: 'Jeg vil gjerne motta e-post om konkurranser',
};

ConfiguredForm.propTypes = {
  formId: string,
  isSubmitting: bool,
  checkboxLabel: string,
};

export default withTheme(ConfiguredForm);
