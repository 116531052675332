import React, { useState, useRef, useEffect } from 'react';
import { func } from 'prop-types';
import Draggable from 'react-draggable';
import { Card, Flex, Label } from '@sanity/ui';
import { FaExpandArrowsAlt as DragIcon, FaTimes as CloseIcon } from 'react-icons/fa';
import styled from 'styled-components';
import { useDoubleClick } from '../../../lib/utils';
import { useStateValue } from '../../../state';

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 12%;
  left: 10%;
  background: rgba(0, 0, 0, 0.2);
  background: transparent;
  color: #cc0000;
  pointer-events: none;
  .handle {
    margin: -10px 10px 10px -10px;
  }
  .handle,
  .shutter {
    color: #999;
    transition: color 100ms ease-out;
    z-index: 100;
    &:hover {
      color: #333;
    }
  }
  [data-ui='Card'] {
    pointer-events: visible;
    background: #f2f3f5cc;
    width: 250px;
    &.mini {
      width: 100px;
    }
  }
`;

const Panel = ({ children, closeHandler, ...props }) => {
  const [mini, setMini] = useState(true);
  const [refCallback] = useDoubleClick(() => setMini(!mini));
  const nodeRef = useRef(null);

  const [{ notCheated }, dispatch] = useStateValue();

  useEffect(() => {
    // Log cheated
    dispatch({ type: 'notCheated', payload: false });
  }, []);

  return (
    <StyledContainer className={mini && 'mini'} {...props}>
      <Draggable
        handle=".handle"
        defaultPosition={{ x: 0, y: 0 }}
        position={null}
        grid={[25, 25]}
        scale={1}
        nodeRef={nodeRef}
        // onStart={handleDrag}
        // onDrag={handleDrag}
        // onStop={handleDrag}
      >
        {mini ? (
          <Card ref={nodeRef} padding={4} radius={4} shadow={5}>
            <Flex justify="flex-start" margin={1}>
              <DragIcon className="handle" />
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  width: '100%',
                  textAlign: 'center',
                  userSelect: 'none',
                }}
              >
                <Label ref={refCallback} style={{ color: '#333' }}>
                  Debug mode
                </Label>
              </div>
              <div style={{ color: '#ccc', position: 'absolute', right: 10, top: 10, padding: 4 }}>
                <CloseIcon className="shutter" onClick={closeHandler} />
              </div>
            </Flex>
            {children}
          </Card>
        ) : (
          <Card
            ref={nodeRef}
            className="mini"
            padding={4}
            radius={4}
            shadow={5}
            style={{ height: 16, width: 16, borderRadius: '50%' }}
          >
            <Flex
              justify="center"
              ref={refCallback}
              align="center"
              style={{ position: 'relative', top: 10, left: 10 }}
            >
              <DragIcon className="handle" />
            </Flex>
          </Card>
        )}
      </Draggable>
    </StyledContainer>
  );
};

Panel.propTypes = {
  closeHandler: func.isRequired,
};

export default Panel;
