import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useKonami from 'use-konami';

import { ThemeProvider } from '@sanity/ui';
import { useLocation, useEffectOnce } from 'react-use';

import { useMediaQuery } from 'react-responsive';
import { getTheme } from '../theme/enchant';
import { urlFor } from '../lib/sanity';
import { getInitials } from '../lib/utils';

import { useStateValue } from '../state';

import Header from './Header';
import TransitionBox from './TransitionBox';
import CustomBackground from './CustomBackground';
import FourOhFour from './FourOhFour';
import DevPanel from './dev/DevPanel';
import { createFire } from '../lib/firestarter';

const WrappedAppStyle = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  color: #330033;
  background-position: center top;
`;

// Staale done something here
const MainAreaStyle = styled.main`
  display: flex;
  min-height: 100vh;
  height: 100vh;
  flex-direction: column;
`;

function WrappedApp() {
  const [{ quiz, fullScreenRef }, dispatch] = useStateValue();
  const [konami, setKonami] = useState(false);
  const [konami2, setKonami2] = useState(false);
  const { search: urlString } = useLocation();

  // Spread the pages into array here and pass on the array
  const pages = [];

  if (quiz.prePages) {
    pages.push(...quiz.prePages);
  }
  if (quiz.questions) {
    pages.push(...quiz.questions);
  }
  if (quiz.postPages) {
    pages.push(...quiz.postPages);
  }

  // console.log({ pages });

  // console.log('key', quiz.themeKey);

  // quiz.themeKey = 'hurtigrutenGroup';

  // console.log({ getTheme });

  // If quiz id = "4507f62d-cd17-4c34-a682-78496d2db6b6" set Christmasdeco to true

  // if (quiz._id === '4507f62d-cd17-4c34-a682-78496d2db6b6') {
  //   console.log('this is the one');
  //   // dispatch[]
  //   dispatch({ type: 'setItsChristmas', payload: true });
  // } else {
  //   console.log('sod off');
  // }

  useKonami({
    onUnlock: () => setKonami(true),
    sequence: ['ArrowLeft', 'ArrowLeft', 'ArrowRight', 'ArrowRight'],
  });
  useKonami({
    onUnlock: () => {
      dispatch({ type: 'setShowQuizURLs', payload: true });
    },
    sequence: ['ArrowUp', 'ArrowLeft', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowDown'],
  });

  const isSmallScreen = useMediaQuery({ query: '(max-width: 667px)' });

  const mainBGImage =
    isSmallScreen && quiz?.backgroundImageMobile
      ? `url(${urlFor(quiz.backgroundImageMobile).width(1500).quality(60).url()})`
      : quiz?.backgroundImage
      ? `url(${urlFor(quiz.backgroundImage).width(3000).quality(60).url()})`
      : 'none';

  const fsRef = useRef(null);

  // ⬇ Create a ref to a fullscreen div and store in state for video & confetti cpts
  useEffect(() => {
    if (!fsRef || !fsRef.current) return;
    if (fsRef.current !== null && fsRef.current !== fullScreenRef?.current) {
      dispatch({ type: 'setFullScreenRef', payload: fsRef });
    }
  }, [fsRef.current]);

  useEffect(() => {
    async function fireTalk() {
      // Create node and retrieve ID.
      // We would set the ID in global state for later.
      // In this example however; we just wait 5 secs and update the node using the ID.

      const fireBaseNode = quiz?._id || 'unnamed';
      const quizName = quiz?.displayTitle || 'unnamed';
      const initials = getInitials(urlString, fireBaseNode, quizName);

      const returnedId = await createFire(fireBaseNode, initials, 'sessionData');
      // Write returnid to global state

      dispatch({ type: 'setFirebaseConfig', payload: { id: returnedId, node: fireBaseNode } });
      // TODO: Do we need to set quizname in state?
      dispatch({ type: 'setQuizName', payload: { quizName: fireBaseNode } });
    }
    fireTalk();
  }, []);

  return quiz ? (
    <ThemeProvider theme={getTheme(quiz?.themeKey)}>
      {konami && <DevPanel totalPages={pages} exitStrategy={() => setKonami(false)} />}
      <WrappedAppStyle style={{ backgroundImage: mainBGImage || 'none' }} ref={fsRef}>
        <CustomBackground />
        <MainAreaStyle>
          <Header />
          {/* <TransitionBox pages={[...quiz?.prePages, ...quiz?.questions, ...quiz?.postPages]} /> */}
          <TransitionBox pages={pages} />
        </MainAreaStyle>
      </WrappedAppStyle>
    </ThemeProvider>
  ) : (
    // REVIEW: Is this still relevant since redirect is handled further up?
    <FourOhFour />
  );
}

export default WrappedApp;
