/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled, { withTheme } from 'styled-components';
import { useLocation } from 'react-use';

import { validationSchemaNEW } from './legacy/validationSchemaNew';
import { initialValues } from './legacy/initialValues';
import { createCustomFormFields } from './formFieldGenerators';
import { useStateValue } from '../../state';
import { extractURLParams } from '../../lib/utils';
import { postWhere } from './formSubmissionHandlers';
import CustomForm from './CustomForm';
import { LegacyForm } from './legacy/LegacyForm';
import { getTempValidationSchema, getCheckboxLabel } from './legacy/legacyFormUtils';

function SignupForm({ theme }) {
  const {
    sanity: {
      brand: {
        strings: { formId = 'telenor', submitErrorMessage, checkboxLabel: tempCheckboxLabel },
        uiLabels,
      },
    },
  } = theme;

  const [submitFailed, setSubmitFailed] = useState(false);

  // Get the message from the checkbox in formConfig

  const [customFormCheckboxLabel, setCustomFormCheckboxLabel] = useState('');

  const [
    {
      quiz: { _id, formToFirebase, displayTitle, customUIlabels, customForm },
      score,
      formKey,
      notCheated,
      quiz,
    },
    dispatch,
  ] = useStateValue();

  // TODO: Include the name of the quiz in the Sanity form entry.

  const language = quiz?.language && quiz?.language.length > 0 ? quiz?.language : 'norwegian';

  // This label now only applies to the legacy form handler.
  const checkboxLabel = useMemo(
    () => getCheckboxLabel(quiz, tempCheckboxLabel),
    [quiz, tempCheckboxLabel],
  );

  // If it's a custom form, locate the checkbox and set the label
  if (customForm) {
    customForm._rawFormBuilder.forEach(field => {
      if (field._type === 'formFieldCheckbox') {
        if (customFormCheckboxLabel.length === 0) {
          setCustomFormCheckboxLabel(field.message);
        }
      }
    });
  }

  // TODO: What if there is more than one checkbox?
  // The message would have to be bound to the id of the checkbox.
  // if special form exists use that formId to get the validation schema

  const temporaryValidationSchema = useMemo(
    () => getTempValidationSchema(formKey, formId),
    [formKey, formId],
  );

  const temporaryValues = initialValues[formId];

  temporaryValues.notCheated = notCheated;

  const { search: urlString } = useLocation();

  const urlParameters = extractURLParams(urlString);

  const contextParameter = urlParameters.sp ? urlParameters.sp : '';

  let formFields = [];
  let customInitialValues = {};

  if (customForm) {
    const result = createCustomFormFields(customForm);
    customInitialValues = result.customInitialValues;
    formFields = result.formFields;
  }

  const postWhereParams = [
    formToFirebase,
    _id,
    score,
    displayTitle,
    temporaryValues,
    customFormCheckboxLabel,
    contextParameter,
    dispatch,
    checkboxLabel,
    setSubmitFailed,
  ];

  return customForm ? (
    <CustomForm
      customInitialValues={customInitialValues}
      customForm={customForm}
      postWhere={(values, actions) => postWhere(values, actions, ...postWhereParams)}
      formFields={formFields}
    />
  ) : (
    <LegacyForm
      initialValues={initialValues}
      formId={formId}
      validationSchemaNEW={validationSchemaNEW}
      uiLabels={uiLabels}
      language={language}
      temporaryValidationSchema={temporaryValidationSchema}
      postWhere={(values, actions) => postWhere(values, actions, ...postWhereParams)}
      submitFailed={submitFailed}
      submitErrorMessage={submitErrorMessage}
    />
  );
}

export default withTheme(SignupForm);
