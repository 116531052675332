export const initialValues = {
  telenor: {
    fullName: '',
    phoneNumber: '',
    email: '',
    acceptedTerms: false,
    notCheated: true,
    textInput: '',
    context: 'defaultvalue',
  },
  hurtigruten: {
    fullName: '',
    firstName: '',
    lastName: '',
    postCode: '',
    phoneNumber: '',
    email: '',
    acceptedTerms: false,
    textInput: '',
    textfield: '',
    notCheated: true,
    context: '',
  },
  volmax: {
    fullName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    acceptedTerms: false,
    textfield: '',
    notCheated: true,
    context: '',
  },
};
