import { common } from './common';

export const hr = {
  red: '#E01C1F',
  darkRed: 'rgb(175, 10, 15)',
  darkBlue: 'rgb(9, 44, 85)',
  colorNeutral: '#1D1D1D',
  white: 'white',
  green: '#66880A',
  grey: '#dfdfdf',
  dark: '#3a3a3a',
  darker: '#232120',
  black: '#000',
  xpeditionGreen: '#6DC88D',
  xpeditionRed: 'rgb(254, 135, 135)',
  colorSecondary: 'blue',
  Austin: 'Austin Text',
  Atlas: 'Atlas Grotesk',
  HurtigrutenBold: 'Hurtigruten Display Condensed',
  fontSizeCopy: '20px',
  fontSizeHeader: '40px',
};

export const hurtigruten = {
  ...common,
  fonts: {
    light: hr.Austin,
    default: hr.Austin,
    copy: hr.Atlas,
  },
  logoAlignCenter: false,
  headerHover: 'black',
  colors: {
    background: 'white',
    color: 'black',
    questionColor: '#fff',
    linkColor: hr.darker,
    primary: {
      default: '#19aaf8',
      darker: '#0078cf',
      lighter: '#19aaf8',
    },
  },

  card: {
    background: 'white',
    color: 'black',
    radius: '0',
  },
  header: {
    mobileMenuFont: hr.Atlas,
    mobileMenuFontColor: hr.darkBlue,
    mobileNavDividerColor: hr.darkBlue,
  },
  frontPage: {
    buttonColor: hr.red,
    buttonColorHover: hr.darkRed,
    fontCopy: hr.Atlas,
  },
  pagesStyles: {
    fontHeading: hr.Austin,
    fontSizeHeading: '2.5em',
    fontHeadingWeight: '600',
    fontSizeCopy: '1.2em',
    fontSizeButton: '1.2em',
    fontCopy: hr.Atlas,
    fontCopyWeight: 'light',
  },
  buttons: {
    radius: '0px',
    hover: hr.darkRed,
    font: hr.Atlas,
    fontSize: hr.fontSizeCopy,
    padding: '0.8em 1.7em 1em',
    weight: '700',
    buttonTextColor: 'white',
  },
  questionStyle: {
    fontQuestion: hr.Atlas,
    fontAnswer: hr.Atlas,
    fontColor: hr.white,
    buttonColor: hr.red,
    buttonColorHover: hr.darkRed,
    fontSizeQuestion: '30px',
    fontSizeAnswer: hr.fontSizeCopy,
    answerButtonRadius: '0',
    answerTextColor: hr.white,
    answerTextWeight: 700,
  },
  feedbackStyle: {
    correctColor: hr.green,
    correctColorHover: hr.green,
    incorrectColor: hr.darkRed,
    incorrectColorHover: hr.darkRed,
    buttonColor: hr.darkBlue,
    buttonColorHover: hr.darkBlue,
    buttonColorSameAsFeedback: false,
    buttonFontWeight: '500',
    buttonFontColor: 'white',
    fontHeader: hr.Austin,
    fontSizeHeader: '40px',
    fontCopy: hr.Atlas,
    fontHeightCopy: 1.3,
    fontSizeCopy: '20px',
    fontButton: hr.Atlas,
    fontSizeButton: '20px',
    fontLinkColor: hr.black,
    noTextDecorationOnLinkHover: true,
  },
  feedbackPageStyle: {
    numberColor: '#092c55',
  },
  links: {
    // Experimental
    borderDotted: true,
    useBorder: true,
    borderOnHover: true,
    hoverColor: 'black',
  },
  formStyle: {
    font: hr.Atlas,
    fontSize: '1.2em',
    buttonColor: hr.red,
    buttonColorHover: hr.darkRed,
  },

  compensation: {
    frontPageBottomMargin: false,
    submitButtonBottomMargin: false,
  },

  confettiColors: [hr.red, hr.darkRed, hr.white, '#000', hr.green],
  strings: {
    ...common.strings,
    startBtnLabel: 'Start',
    restartBtnLabel: 'Restart',
    proceedBtnLabel: 'Proceed',
    submitBtnLabel: 'Submit',
    submittingBtnLabel: 'Submitting...',
    submitErrorMessage: 'Oops! Something went wrong, and the form was not submitted',
    checkboxLabel: 'I’m fine with Hurtigruten sharing my data!',
    formId: 'hurtigruten',
    inactiveQuizLabel: 'The quiz is no longer active.',
  },

  // Only HR for now
  uiLabels: {
    english: {
      form: {
        name: 'Name',
        phone: 'Phone (Optional)',
        email: 'Email',
        submit: 'Submit',
        submitting: 'Submitting …',
      },
      interaction: {
        startBtnLabel: 'Start',
        restartBtnLabel: 'Restart',
        proceedBtnLabel: 'Proceed',
        submitBtnLabel: 'Submit',
        submittingBtnLabel: 'Submitting...',
        submitErrorMessage: 'Oops! Something went wrong, and the form was not submitted',
        //   checkboxLabel: 'I’m fine with Hurtigruten sharing my story!',
      },
      validation: {
        fullName: 'Must be at least 3 characters',
        phoneNumber: 'Must have at least 8 digits',
        email: 'Please enter a valid email address',
        requiredField: 'This field is required!',
      },
    },
    norwegian: {
      form: {
        name: 'Navn',
        firstName: 'Fornavn',
        lastName: 'Etternavn',
        phone: 'Telefonnummer',
        email: 'E-post',
        postCode: 'Postnummer',
        consentMessage: 'Ok å motta info fra etc.',
        submit: 'Send inn',
        submitting: 'Sender…',
      },
      validation: {
        fullName: 'Minst tre bokstaver',
        firstName: 'Minst tre bokstaver',
        lastName: 'Minst tre bokstaver',
        phoneNumber: 'Minst 8 siffer',
        postCode: 'Minst 4 tegn',
        email: 'Ugyldig e-postadresse',
        requiredField: 'Obligatorisk felt!',
      },
    },
    french: {
      interaction: {
        quizInactive: 'This campaign is no longer active.',
      },
      form: {
        name: 'Nom',
        phone: 'Numéro de téléphone',
        email: 'E-mail',
        consentMessage: 'Consent message in French',
        submit: 'Valider',
        submitting: 'Envoi… ',
        submissionError:
          'Oups ! Une erreur est survenue. Le formulaire n’a pas été envoyé. Veuillez réessayer plus tard. ',
      },

      validation: {
        fullName: 'Doit contenir au moins 3 caractères',
        phoneNumber: 'Doit contenir au moins 8 chiffres',
        email: 'Veuillez saisir une adresse e-mail valide',
        requiredField: 'Ce champ est requis!',
      },
    },
    german: {
      interaction: {
        startBtnLabel: 'Start',
        restartBtnLabel: 'Erneut starten',
        proceedBtnLabel: 'Fortfahren',
        submitBtnLabel: 'Senden',
        submittingBtnLabel: 'Wird gesendet …',
        submitErrorMessage:
          'Hoppla! Leider ist ein Fehler aufgetreten. Das Formular wurde nicht gesendet. Bitte versuchen Sie es später noch einmal.',
        quizInactive: 'Diese Aktion ist leider nicht mehr verfügbar.',
      },
      form: {
        name: 'Name',
        phone: 'Telefonnummer',
        email: 'E-Mail-Adresse',
        submit: 'Senden',
        submitting: 'Wird gesendet …',
      },
      validation: {
        fullName: 'Muss mindestens 3 Zeichen lang sein',
        phoneNumber: 'Muss mindestens 8 Zeichen lang sein',
        email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        requiredField: 'Dies ist ein Pflichtfeld',
      },
    },
  },

  // New paradigm
  nav: `
    font-family: ${hr.Atlas};
    li:hover {
      color: ${hr.dark};
    }
  `,

  mobileMenu: `
    li {
      padding: 0;
      font-family: ${hr.Atlas};
      background: ${hr.red};
      a {
        color: white;
      }
    }
  `,

  standardPage: {
    basic: `
        font-family: ${hr.Austin};
        `,

    h1: ` h1{
          font-size: 2.5em;
          font-weight: 600;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
        }`,

    h2: ` h2 {
        }
        
        `,

    p: `p {
          font-family: ${hr.Atlas};
          font-size: 1.2em;
          line-height: 1.4;
        }`,
  },

  basicButton: `
  font-family: ${hr.Atlas};
  background: ${hr.red};
  color: white;
  padding: 0.8em 1.7em 1em;
  font-size: 20px;
  font-weight: 700;
  border: none;
  border-radius: none;
  transition: .3s;
  `,

  basicButtonHover: `
    background: ${hr.darkRed};
  
  `,

  question: `
    font-family: ${hr.Atlas};
    font-size: 30px;
    `,

  feedback: `
    font-family: ${hr.Austin};
    h1 {
      font-size: 40px;
      
    }
    p {
      font-size: 20px;
      font-family: ${hr.Atlas};

    }

    &.correct {
      h1 {
        color: ${hr.green};
      }
      button {
        background: ${hr.darkBlue};
      }
    }

    &.incorrect {
      h1 {
        color: ${hr.darkRed};
      }
      button {
        background: ${hr.darkBlue};
      }
    }
  `,
  answers: `
    font-family: ${hr.Atlas};
    background: ${hr.red};
    color: white;
    border: none;
    transition: 0;
    &:hover {
      background: ${hr.darkRed};
    }
  `,
};

export const hurtigrutenGroup = {
  ...hurtigruten,
  frontPage: {
    borderColor: hr.dark,
    borderColorHover: hr.dark,
    buttonTextColor: hr.black,
    buttonTextColorHover: hr.white,
    buttonColor: hr.white,
    buttonColorHover: hr.dark,
  },
  buttons: {
    ...hurtigruten.buttons,
    // radius: '0px',
    // hover: hr.darkRed,
    // font: hr.Atlas,
    // fontSize: hr.fontSizeCopy,
    // padding: '0.8em 1.7em 1em',
    weight: '600',
    buttonTextColor: hr.dark,
    transition: '0s',
  },
  pagesStyles: {
    fontHeading: hr.Atlas,
    fontSizeHeading: '2.5em',
    fontHeadingWeight: '700',
    fontSizeCopy: '1.2em',
    fontSizeButton: '1.2em',
    fontCopy: hr.Atlas,
    fontCopyWeight: 'light',
  },
  questionStyle: {
    ...hurtigruten.questionStyle,

    // fontQuestion: hr.Atlas,
    fontQuestionWeight: 700,
    // fontAnswer: hr.Atlas,
    fontColor: hr.darker,
    buttonColor: hr.white,
    buttonBorderColor: hr.darker,
    answerTextColor: hr.darker,
    // answerButtonRadius: '0',
    // answerTextWeight: 700,

    buttonColorHover: hr.darker,
    buttonTextColorHover: hr.white,
    // fontSizeQuestion: '30px',
    // fontSizeAnswer: hr.fontSizeCopy,
  },
  feedbackStyle: {
    ...hurtigruten.feedbackStyle,
    fontHeader: hr.Atlas,

    buttonColor: hr.white,
    buttonColorHover: hr.darker,
    buttonColorSameAsFeedback: false,
    buttonFontWeight: '500',
    buttonFontColor: hr.darker,
  },
  feedbackPageStyle: {
    numberColor: hr.red,
  },
  links: {
    // Experimental
    // color: hr.red,
    // borderDotted: true,
    borderOnHover: true,
    borderDotted: true,

    // useBorder: true,
    // borderOnHover: true,

    hoverColor: hr.darker,
  },
  formStyle: {
    ...hurtigruten.formStyle,
    // font: hr.Atlas,
    // fontSize: '1.2em',
    buttonTextColor: hr.dark,
    buttonTextColorHover: hr.white,
    buttonColor: hr.white,
    buttonColorHover: hr.dark,
    borderColor: hr.dark,
  },

  // New paradigm

  mobileMenu: `
    li {
      padding: 0;
      font-family: ${hr.Atlas};
      // background: ${hr.red};

      // background: black;

      background: white;
      border: 1px solid black;
      a {
        color: black;
      }
    }
  `,

  standardPage: {
    ...hurtigruten.standardPage,
    h1: `h1{
          font-family: ${hr.Atlas};
          font-size: 2.5em;
          font-weight: 700;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
       
        }`,
  },

  basicButton: `
  font-family: ${hr.Atlas};
  background: white;
  color: black;
  padding: 0.8em 1.7em 1em;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid ${hr.dark};
  border-radius: none;
  transition: .3s;
  `,

  basicButtonHover: `
    background: ${hr.dark};
    color: white;
  
  `,

  question: `
    font-family: ${hr.Atlas};
    font-size: 30px;
    font-weight: 700;
    `,

  answers: `
    font-family: ${hr.Atlas};
    background: white;
    color: black;
    border: 1px solid black;
    transition: 0;
    &:hover {
      background: ${hr.black};
    }
  `,
  feedback: `
    // font-family: ${hr.Austin};
    h1 {
      font-size: 40px;
      font-family: ${hr.Atlas};
      
    }
    p {
      font-size: 20px;
      font-family: ${hr.Atlas};

    }
    button {
      background: white;
      border: 1px solid black;
      color: ${hr.dark};
      font-weight: 500;
      &:hover {
        background: ${hr.darker};
        color: white;
      }
    }

    &.correct {
      h1 {
        color: ${hr.green};
      }
      button {
        background: white;
        border: 1px solid black;
        color: ${hr.dark};
        font-weight: 500;
        &:hover {
          background: black;
          color: white;
        }
      }
    }

    &.incorrect {
      h1 {
        color: ${hr.darkRed};
      }
    }
  `,
};

export const hurtigrutenExpedition = {
  ...hurtigruten,

  colors: {
    ...hurtigruten.colors,
    background: 'black',
    color: 'white',
  },

  basicButton: hurtigrutenGroup.basicButton,

  colors: {
    ...hurtigruten.colors,
    background: hr.black,
    color: 'white',
  },

  // card: {
  //   background: hr.black,
  //   color: 'white',
  //   radius: '0',
  // },

  header: {
    height: 40,
    logoHeight: 30,
    headerCompensate: 76,
  },

  questionStyle: {
    ...hurtigrutenGroup.questionStyle,
    // fontQuestionWeight: '700',
    answerTextWeight: 600,
  },

  feedbackPageStyle: {
    numberColor: '#1FB1D1',
  },

  nav: `
    font-family: ${hr.Atlas};
    li:hover {
      color: white;
      text-decoration: underline;
    }
  `,

  mobileMenu: `
    li {
      padding: 0;
      font-family: ${hr.Atlas};

      background: white;
      border: 1px solid black;
      border-radius: 0;
      a {
        color: black;
      }
    }
  `,

  standardPage: {
    ...hurtigruten.standardPage,
    h1: `h1{
          font-family: ${hr.HurtigrutenBold};
          font-size: 4.5em;
          font-weight: 700;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
          margin-top: 20px;
          line-height: 0.85;
        }`,

    li: `
        
        font-family: ${hr.Atlas};

        `,
  },

  basicButton: `
  font-family: ${hr.Atlas};
  background: white;
  color: black;
  padding: 0.8em 1.7em 1em;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid ${hr.dark};
  border-radius: none;
  transition: .3s;
  `,

  basicButtonHover: `
    background: ${hr.dark};
    color: white;
    border: 1px solid black;
  `,

  answers: `
    font-family: ${hr.Atlas};
    background: white;
    color: black;
    border: 1px solid black;
    transition: 0;
    &:hover {
      background: ${hr.dark};
    }
  `,
  feedback: `
    // font-family: ${hr.Austin};
    h1 {
      font-size: 40px;
      font-family: ${hr.Atlas};
      
    }
    p {
      font-size: 20px;
      font-family: ${hr.Atlas};

    }
    button {
      background: white;
      border: 1px solid black;
      color: ${hr.dark};
      font-weight: 500;
      &:hover {
        background: ${hr.darker};
        color: white;
      }
    }

    &.correct {
      h1 {
        color: ${hr.xpeditionGreen};
      }
      button {
        background: white;
        border: 1px solid black;
        color: ${hr.dark};
        font-weight: 500;
        &:hover {
          background: black;
          color: white;
        }
      }
    }

    &.incorrect {
      h1 {
        color: ${hr.xpeditionRed};
      }
    }
  `,

  question: `
    font-family: ${hr.Atlas};
    font-size: 30px;
    font-weight: 700;
    `,
};
