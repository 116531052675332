import React from 'react';
import styled, { withTheme } from 'styled-components';
import { string } from 'prop-types';

// TODO: This is a temporary measure. We need a generalised button that has the
// appropriate styling and no specific onclick action.

const AlternativeButtonStyle = styled.button`
  // "Deaden" the button when it is disabled.
  ${({ disabled }) => disabled && 'opacity: 0.1;'}

  /* font-family: var(--font, 'telenorregular'); */
  /* font-size: var(--fontSize, 1.2em); */
  /* background: var(--buttonColor, #19aaf8); */
  /* &:hover {
    background: var(--buttonColorHover, #0078cf);

    color: ${p => p.theme?.sanity?.brand?.frontPage?.buttonTextColorHover || 'white'};
    background: ${p => p.theme?.sanity?.brand?.frontPage?.buttonColorHover || '#333'};
    /* TODO: Dynamic hover colors */
  /* }  */

  /*   
  color: var(--buttonTextColor, white);
  font-weight: var(--weight, normal);
  border-radius: var(--radius, 3px); */

  transition: 0.3s;
  box-shadow: 0;
  cursor: pointer;
  margin-top: 1.2em;

  /* border: 1px solid ${p => p.theme?.sanity?.brand?.frontPage?.borderColor || 'none'}; */

  // TODO: here remove some more

  ${p => p.theme?.sanity?.brand?.basicButton};
  &:hover {
    ${p => p.theme?.sanity?.brand?.basicButtonHover};
  }
`;

const SubmitButton = ({ type, children, theme }) => {
  const {
    sanity: {
      brand: { formStyle, buttons, compensation },
    },
  } = theme;

  return (
    <AlternativeButtonStyle
      style={{
        '--font': formStyle?.font,
        '--fontSize': formStyle?.fontSize,
        '--buttonColor': formStyle?.buttonColor,
        '--buttonBorder': formStyle?.borderColor,
        '--buttonColorHover': formStyle?.buttonColorHover,
        '--radius': buttons?.radius,
        '--buttonTextColor': buttons?.buttonTextColor,
        '--weight': buttons?.weight,
        // marginBottom: compensation.submitButtonBottomMargin || '',
      }}
      type={type}
    >
      {children}
    </AlternativeButtonStyle>
  );
};

SubmitButton.defaultProps = {
  type: 'button',
};

SubmitButton.propTypes = {
  type: string,
};

export default withTheme(SubmitButton);
