/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useRef } from 'react';
import styled, { withTheme } from 'styled-components';
import { createPortal } from 'react-dom';
import { func, number } from 'prop-types';
import { useWindowSize, useClickAway } from 'react-use';
import ReactConfetti from 'react-confetti';

import { useStateValue } from '../state';

const StyledContainer = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 1000ms;
  &.exiting {
    opacity: 0;
  }
`;

const Confetti = ({ timedExit, wait, theme }) => {
  const {
    sanity: {
      brand: { confettiColors },
    },
  } = theme;
  const [exiting, setExiting] = useState(false);
  const [{ fullScreenRef }] = useStateValue();
  const { width, height } = useWindowSize();

  useEffect(() => {
    const fader = setTimeout(() => setExiting(true), wait);
    const remover = setTimeout(timedExit, wait + 1000);
    return () => {
      clearTimeout(fader);
      clearTimeout(remover);
    };
  });
  const ref = useRef(null);
  useClickAway(ref, () => {
    setExiting(true);
  });
  return fullScreenRef?.current
    ? createPortal(
        <StyledContainer className={exiting && 'exiting'}>
          <ReactConfetti colors={confettiColors} width={width} height={height} />
          <span ref={ref}>&nbsp;</span>
        </StyledContainer>,
        fullScreenRef.current,
      )
    : null;
};

Confetti.defaultProps = {
  wait: 5000,
};

Confetti.propTypes = {
  timedExit: func.isRequired,
  wait: number,
};

export default withTheme(Confetti);
