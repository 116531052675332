import { common } from './common';

export const es = {
  fontLight: 'Roboto',
  fontRegular: 'Roboto',
  yngFontLight: 'Roboto',
  yngFontRegular: '',

  fontBig: '2em',
  fontStandard: '1.2em',

  // YNG cascade
  yngFontSizeLarge: '30px',
  yngFontSizeMedium: '24px',
  yngFontSizeSmall: '18px',

  blueMain: '#58acff',
  // blueMain: 'darkslategray',
  blueDark: '#203a53',
  colorCorrect: 'darkolivegreen',
  colorIncorrect: 'tomato',
  colorCorrectYng: '#D1E5DB',
  colorIncorrectYng: '#FDEEF1',
  borderRadius: '3px',
};

export const evokedsetBasic = {
  ...common,
  fonts: {
    light: 'sans-serif',
    default: 'Poppins',
  },
  logoAlignCenter: false,
  headerHover: 'black',
  colors: {
    background: '#203a53',
    color: '#333',
    questionColor: '#fff',
    linkColor: 'tomato',
    primary: {
      default: 'tomato',
      darker: 'crimson',
      lighter: 'hotpink',
    },
    win: {
      default: '#28a845',
    },
    fail: {
      default: '#FF648C',
    },
  },
  // buttons: {
  //   radius: '3px',
  //   hover: 'tomato',
  //   font: 'Poppins',
  // },

  compensation: {
    frontPageBottomMargin: false,
    submitButtonBottomMargin: false,
  },

  strings: {
    ...common.strings,
  },

  nav: `
    font-family: ${es.fontLight};
    li:hover {
      color: #666;
    }
  `,

  mobileMenu: `
    li {
      border: 0;
      background: ${es.blueMain};
    }
  `,

  standardPage: {
    basic: `
        font-family: ${es.fontLight};
        `,

    h1: ` h1{
          font-size: ${es.fontBig};
          font-weight: normal;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
        }`,

    h2: ` h2 {
          // font-family: ${es.fontLight};
        }
        
        `,

    p: `p {
          font-size: ${es.fontStandard};
          
        }`,
  },
  basicButton: `
      font-family: ${es.fontRegular || 'sans-serif'};
      transition: 0.3s; /* If no themes have this, stick it in the component instead */
      box-shadow: 0;
      border: none;
      background: ${es.blueMain};
      padding: 0.8em 1.7em 1em;
      border-radius: ${es.borderRadius};
      font-size: 1.2em;
      font-weight: normal;
      color: white;
      border: none;
      
      &:hover {
        color: white;
        background: ${es.blueDark};
      }
      `,

  question: `
    font-family: ${es.fontRegular};
    font-size: ${es.fontStandard};
    `,

  feedback: `
    font-family: ${es.fontRegular};
    h1 {
      font-size: ${es.fontBig}

    }
    p {
      font-size: 1.5em;
    }

    &.correct {
      h1 {
        color: ${es.colorCorrect};
      }
      button {
        background: ${es.colorCorrect};
      }
    }

    &.incorrect {
      h1 {
        color: ${es.colorIncorrect};
      }
      button {
        background: ${es.colorIncorrect};
      }
    }

  `,
  answers: `
    font-family: ${es.fontRegular};
    background: ${es.blueMain};
    color: white;
    border: 0;
    transition: 0;
    &:hover {
      background: ${es.blueDark};
      // color: black;
    }
    `,
  form: `
    font-family: ${es.fontLight};
  `,
};
