import React from 'react';
// import SanityImage from 'gatsby-plugin-sanity-image';

import { arrayOf, object, string } from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { urlFor } from '../../lib/sanity';
import { useStateValue } from '../../state';

import Brandbox from '../Brandbox';
import FlipCard from '../FlipCard';
import AnimAnswers from '../AnimAnswers';
import VideoPlayer from '../VideoPlayer';
import PortableText from '../PortableText';
import ProceedButton from '../ProceedButton';
import { updateFire } from '../../lib/firestarter';

const QuestionStyle = styled.div`
  /* /* font-family: var(--fontQuestion, sans-serif); */

  /* p {
    font-size:  1.2em);
    font-weight: normal;
  } */
  .image-wrap {
    position: relative;
    top: 0;
    left: 0;
    margin: -32px -32px 0 -32px;
    img {
      width: 100%;
    }
  }

  ${p => p.theme?.sanity?.brand?.question}
`;

// @TODO: cleanup needed here, fonts should be programatically assigned
const StyledFeedback = styled.div`
  text-align: left;
  /* font-family: var(--fontCopy, 'sans-serif');
  h1 {
    color: var(--feedbackColor);
    font-family: var(--fontHeader, 'sans-serif');
    font-size: var(--fontSizeHeader);
  }
  p {
    font-family: var(--fontCopy, 'sans-serif');
    font-size: var(--fontSizeCopy);
    line-height: var(--fontHeightCopy, 1.2);
  }
  a {
    color: var(--fontLinkColor);
    &:hover {
      text-decoration: ${p =>
    p.theme?.sanity?.brand?.feedbackStyle?.noTextDecorationOnLinkHover ? 'none' : 'underline'};
    }
  }
  button {
    font-family: var(--fontButton, 'sans-serif');
    background: var(--feedbackButtonColor, 'black');
    color: var(--buttonFontColor);
    font-weight: var(--buttonFontWeight);
    /* border: 0; */
  /* border: 1px solid ${p => p.theme?.sanity?.brand?.frontPage?.buttonBorderColor || 'none'}; */
  /* font-size: var(--fontSizeButton);
    &:hover {
      background: var(--feedbackButtonColorHover); */
  /* TODO: This needs some colour manipulations */
  /* } */
  /* } */
  /* font-family: 'gtpressuralight'; */

  // TODO: Staale legg inn her.

  ${p => p.theme?.sanity?.brand?.feedback}
`;

/**
 * This component uses the withTheme wrapper function to make the theme
 * available through props. The default export at the end of the file is
 * shows how to wrap the component. We could achieve the same result by
 * simply accessing the theme from within the styled.div, like so:
 * color: ${p => p.theme.sanity.brand.colors.win.default};
 */

const Question = ({ type, text, answers, image, video, theme }) => {
  const [{ feedback, score, firebaseConfig }, dispatch] = useStateValue();

  function removeAndProgress() {
    dispatch({ type: 'moveForward' });
    setTimeout(() => dispatch({ type: 'setFeedback', payload: null }), 300);

    // Send score to firebase, updating the existing value

    updateFire(firebaseConfig, { score });
  }

  /* Destructure the values we're interested in from the theme */
  const {
    sanity: {
      brand: { questionStyle, feedbackStyle },
    },
  } = theme;

  let feedbackColor;
  let feedbackColorHover;
  let feedbackButtonColor;
  let feedbackButtonColorHover;

  if (feedback) {
    feedbackColor = feedback.isCorrect
      ? feedbackStyle?.correctColor
      : feedbackStyle?.incorrectColor;
    feedbackColorHover = feedback.isCorrect
      ? feedbackStyle?.correctColorHover
      : feedbackStyle?.incorrectColorHover;
    if (feedbackStyle?.buttonColorSameAsFeedback) {
      feedbackButtonColor = feedbackColor;
      feedbackButtonColorHover = feedbackColorHover; // TODO:
    } else {
      feedbackButtonColor = feedbackStyle?.buttonColor;
      feedbackButtonColorHover = feedbackStyle?.buttonColorHover; // TODO:
    }
  }
  const correct = feedback && feedback.isCorrect ? 'correct' : 'incorrect';

  return (
    <FlipCard isFlipped={!!feedback}>
      <Brandbox>
        <QuestionStyle
          style={{
            '--fontQuestion': questionStyle?.fontQuestion,
            '--fontColor': questionStyle?.fontColor,
            '--buttonColor': questionStyle?.buttonColor,
            '--buttonColorHover': questionStyle?.buttonColorHover,
            '--fontSizeQuestion': questionStyle?.fontSizeQuestion,
            '--fontQuestionWeight': questionStyle?.fontQuestionWeight,
            '--fontSizeAnswer': questionStyle?.fontSizeAnswer,
          }}
        >
          {type === 'textAndVideoQuestion' && <VideoPlayer url={video.url} />}
          {type === 'textAndImageQuestion' && (
            <div className="image-wrap">
              <img alt="placeholder-alt" src={urlFor(image.asset).width(600).url()} />
              {/* <SanityImage {...image} width={700} alt="placeholder-alt" /> */}
            </div>
          )}
          {text && <PortableText content={text} />}
        </QuestionStyle>
        <AnimAnswers answers={answers} />
      </Brandbox>
      <Brandbox>
        {feedback && (
          <StyledFeedback
            isCorrect={feedback.isCorrect}
            /* Add the color as a CSS variable depending on the value of isCorrect */
            style={{
              '--feedbackColor': feedbackColor,
              '--feedbackButtonColor': feedbackButtonColor,
              '--feedbackButtonColorHover': feedbackButtonColorHover,
              '--fontHeader': feedbackStyle?.fontHeader,
              '--fontSizeHeader': feedbackStyle?.fontSizeHeader,
              '--fontCopy': feedbackStyle?.fontCopy,
              '--fontSizeCopy': feedbackStyle?.fontSizeCopy,
              '--fontHeightCopy': feedbackStyle?.fontHeightCopy,
              '--fontLinkColor': feedbackStyle?.fontLinkColor,
              '--fontButton': feedbackStyle?.fontButton,
              '--fontSizeButton': feedbackStyle?.fontSizeButton,
              '--buttonFontWeight': feedbackStyle?.buttonFontWeight,
              '--buttonFontColor': feedbackStyle?.buttonFontColor,
            }}
            className={correct}
          >
            <PortableText
              style={{
                border: '4px solid red',
              }}
              className="incorrect"
              content={feedback.content}
            />
            <ProceedButton type="wide" label="proceed" onClick={removeAndProgress} />
          </StyledFeedback>
        )}
      </Brandbox>
    </FlipCard>
  );
};

Question.defaultProps = {
  image: null,
  video: null,
};

Question.propTypes = {
  type: string.isRequired,
  text: arrayOf(object).isRequired,
  answers: arrayOf(object).isRequired,
  image: object,
  video: object,
};

export default withTheme(Question);
