import React, { useRef } from 'react';
import { object, func, number } from 'prop-types';
import styled from 'styled-components';
import { Card } from '@sanity/ui';
import { useClickAway, useMeasure } from 'react-use';
import { IoMdCloseCircle } from 'react-icons/io';

import PageLayout from './PageLayout';

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 6em);
  width: 100%;
  height: 100vh;
  z-index: 100;
  /* padding: 3em; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  p,
  li {
    font-size: 1.2rem;
  }
  ul {
    padding: 0 1em;
    li {
      margin-bottom: 0.5em;
    }
  }
  .modalpage {
    display: block;
    z-index: 1;
    max-width: 1024px;
    max-width: 45rem;
    text-align: right;
    /* margin: 1em; */
    margin: 3em;
  }
  .modal-inner {
    text-align: left;
    padding: 2em;
    /* padding: 0; */
    /* padding: 0 3em; */
    h1 {
      margin-top: 0;
    }
  }
  .button-wrap {
    display: flex;
    align-items: right;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    button {
      svg {
        width: 40px;
        height: 40px;
      }
      padding: 0;
      background: none;
      border: 0;
      margin: 5px;
      cursor: pointer;
    }
  }
  .smallscreen-button {
    display: none;
  }

  @media (min-width: 801px) {
    &.modalTooTall {
      display: block;
      overflow: auto;
      margin: auto;
    }
  }

  @media (max-width: 800px) {
    //
    display: block;
    /* width: auto; */
    width: calc(100vw - 6em);
    padding: 0 3em;
    .modalpage {
      display: block;
      padding: 2em 2em 2em;
      height: calc(100vh - 100px);
      /* height: calc(100vh - 140px); */
      margin: 1em;
    }
    .modal-inner {
      display: block;
      overflow: auto;
      // This must match the outer dimensions. Better solution?
      height: calc(100vh - 100px);
      padding: 0;
    }
    .button-wrap {
      height: 0px;
      button {
        cursor: pointer;
        width: 100%;
        top: 0;
        text-align: right;
        position: relative;
        color: black;
        margin-bottom: 0;
        &.smallscreen-button {
          display: block;
          height: 55px;
          /* overflow: visible; */
          padding-top: 10px;
          position: relative;
          top: 8px;
        }
        &.bigscreen-button {
          display: none;
        }
        cursor: pointer;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0 1em;
    width: calc(100vw - 2em);
    .modalpage {
      height: calc(100vh - 100px);
      padding: 2em;
      /* border: 4px solid red; */
    }
  }
`;

const Modal = ({ content, closeCallback, parentHeight }) => {
  const { body, image, blockLayout } = content;
  const modalRef = useRef(null);
  useClickAway(modalRef, () => closeCallback());

  const [containerRef, { height: containerHeight }] = useMeasure();

  const lowScreen = containerHeight < parentHeight;

  return (
    <StyledOverlay className={!lowScreen ? 'modalTooTall' : ''}>
      <div className="button-wrap">
        <button type="button" className="smallscreen-button close-button" onClick={closeCallback}>
          <IoMdCloseCircle />
        </button>
      </div>
      <Card
        ref={modalRef}
        className="modalpage"
        radius={2}
        shadow={2}
        style={{ minHeight: '20vh', margin: '1em auto 2em' }}
      >
        <div ref={containerRef} className="modal-inner">
          <div className="button-wrap">
            <button type="button" className="bigscreen-button close-button" onClick={closeCallback}>
              <IoMdCloseCircle />
            </button>
          </div>
          <PageLayout blockLayout={blockLayout} text={body} image={image} isModal />
        </div>
      </Card>
    </StyledOverlay>
  );
};

Modal.defaultProps = {
  parentHeight: 0,
};

Modal.propTypes = {
  content: object.isRequired,
  closeCallback: func.isRequired,
  parentHeight: number,
};

export default Modal;
