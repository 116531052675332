import React from 'react';
import { useStateValue } from './state';

import WrappedApp from './cpt/WrappedApp';
import Spinner from './cpt/Spinner';

const App = () => {
  const [{ quiz }] = useStateValue();

  return (
    <>
      {quiz ? (
        <WrappedApp />
      ) : (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner color={'#ccc'} />
        </div>
      )}
    </>
  );
};

export default App;
