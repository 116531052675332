// Import studio theme

import { studioTheme } from '@sanity/ui';
import { brandThemes } from './brand';

// Import other brand themes

// Pick the brand theme
// const currentBrand = 'telenorDark';

// Read from the current brand node

// const brand = brandThemes[currentBrand];

// Export the theme

export const getTheme = (key = 'evokedsetBasic') => ({
  ...studioTheme,
  brand: brandThemes[key],
});

// export default {
//   ...studioTheme,
//   brand,
// };
