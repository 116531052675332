import React, { useEffect, useRef } from 'react';
import { array } from 'prop-types';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { resolveCustomBackground } from './CustomBackground';
import Modal from './Modal';
import Sequenced from './Sequenced';

import { useStateValue } from '../state';
import { resetBrowser, useDimension } from '../lib/utils';

const StageStyle = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &.lowScreen {
    display: block;
    overflow-y: scroll;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
    }
  }

  /* ${p => console.log(p.theme)} */
  @media (max-width: ${p => p.theme?.sanity?.brand?.media?.xsmall || '786'}px) {
    display: block;
    overflow: auto;
    // Staale: I added this to avoid weird behaviour on mid sized screens.
    /* max-width: 500px; */
    margin: auto;
  }
`;
// TODO: replace with dynamic media query

function TransitionBox({ pages }) {
  const [{ currentPage, feedback, modal, customBackground }, dispatch] = useStateValue();
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const { height: containerHeight } = useDimension(containerRef);
  const { height: contentHeight } = useDimension(contentRef);

  const lowScreen = containerHeight < contentHeight;

  // console.log({ pages });

  useEffect(() => {
    // REVIEW:: This is probably too convoluted. Written while debugging chrome bg render bug.
    const customBG = resolveCustomBackground(pages[currentPage]);
    if (customBG !== customBackground) dispatch({ type: 'setCustomBG', payload: customBG });
    resetBrowser(containerRef);
  }, [currentPage, feedback]);

  // If the first page is a conditional page, then don't show the button
  const blockStartButton = pages[currentPage]._type === 'conditionalPage' ? true : false;

  return (
    <StageStyle ref={containerRef} className={lowScreen ? 'lowScreen' : ''}>
      <div ref={contentRef}>
        <AnimatePresence exitBeforeEnter>
          <Sequenced
            key={pages[currentPage]._key}
            content={pages[currentPage]}
            isLast={currentPage >= pages.length - 1}
            isFirst={currentPage === 0}
            blockStartButton={blockStartButton}
          />
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {modal && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Modal
                content={modal}
                parentHeight={containerHeight}
                closeCallback={() => dispatch({ type: 'setShowModal', payload: null })}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </StageStyle>
  );
}

TransitionBox.propTypes = {
  pages: array.isRequired,
};
export default TransitionBox;
