import React, { useEffect } from 'react';
import Spinner from './Spinner';

const FourOhFour = () => {
  useEffect(() => {
    // TODO: Soft code
    const redir = setTimeout(() => {
      window.location = 'https://telenor.no';
    }, 10000);
    return () => {
      clearInterval(redir);
    };
  });
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: '#333',
      }}
    >
      <Spinner />
    </div>
  );
};

export default FourOhFour;
