import { postToApi, postToFirebaseAPI } from './fns';

export const postWhere = (
  values,
  actions,
  formToFirebase,
  _id,
  score,
  displayTitle,
  temporaryValues,
  customFormCheckboxLabel,
  contextParameter,
  dispatch,
  checkboxLabel,
  setSubmitFailed,
) => {
  console.log('Post to Firebase, or not?', formToFirebase);

  if (formToFirebase) {
    // Firebase version
    postToFirebase(
      values,
      actions,
      formToFirebase,
      _id,
      score,
      displayTitle,
      temporaryValues,
      customFormCheckboxLabel,
      contextParameter,
      dispatch,
    );
  } else {
    // Post to Sanity
    postToSanity(
      values,
      actions,
      _id,
      score,
      checkboxLabel,
      contextParameter,
      setSubmitFailed,
      dispatch,
    );
  }
};

// Create a function that calls the firebase API

export const postToFirebase = (
  values,
  actions,
  formToFirebase,
  _id,
  score,
  displayTitle,
  temporaryValues,
  customFormCheckboxLabel,
  contextParameter,
  dispatch,
) => {
  // export const postToFirebase = (values, actions, formToFirebase) => {
  // If post to firebase is not enabled, don't move forward
  // after submitting but move on to the Sanity submit.

  // Explicitly set the boolean
  if (typeof values['consent'] !== 'boolean') {
    values['consent'] = false;
  }
  postToFirebaseAPI({
    //TODO: this can be phased out once we quit Sanity
    docRef: { _type: 'reference', _ref: _id },
    // Whereas, we're keeping this.
    quizId: _id,
    score,
    date: new Date().toISOString(),
    cheated: !temporaryValues.notCheated,
    displayTitle,
    consentMessage: values.consent ? customFormCheckboxLabel : '',
    context: contextParameter,
    isProcessed: false,
    ...values,
  }).then(res => {
    if (res.response?.body?.error) {
      // console.log({ res });
      // console.log(res.response.body.error);
      actions.setSubmitFailed(true);
      console.log('submit failed');
      // TODO: Log this bit to somewhere that alerts us if the form fails
    } else {
      // If formToFirebase is false, don't move forward
      // console.log('postingtosanitynow');
      // postToSanity(values, actions);
      // if (formToFirebase) {
      actions.setSubmitting(false);
      actions.resetForm();
      dispatch({ type: 'moveForward' });
      // }
      // else {
      // Post to Sanity and then move forward.
      // console.log('posting to sanity now');
      // postToSanity(values, actions);
      // }
      // return;
    }
  });
};

export const postToSanity = (
  values,
  actions,
  _id,
  score,
  checkboxLabel,
  contextParameter,
  setSubmitFailed,
  dispatch,
) => {
  console.log('Posting to sanity');

  postToApi({
    docRef: { _type: 'reference', _ref: _id },
    score,
    consentMessage: checkboxLabel,
    ...values,
    context: contextParameter,
  })
    .then(res => {
      if (res.response?.body?.error) {
        console.log(res.response.body.error);
        setSubmitFailed(true);
      } else {
        actions.setSubmitting(false);
        actions.resetForm();
        dispatch({ type: 'moveForward' });
      }
    })
    .catch(error => {
      console.log('An error occurred:', error);
      // Handle the error as per your requirements
    });
};
