/* ⬇ Prefab variants ⬇ */
export const bounceRotate = isFirst => ({
  initial: {
    x: -300,
    opacity: 0,
    rotate: '-40deg',
  },
  animate: {
    x: 0,
    opacity: 1,
    rotate: '0deg',
    transition: {
      delay: isFirst ? 1 : 0,
      ease: 'easeOut',
    },
  },
  exit: {
    x: 300,
    opacity: 0,
    rotate: '40deg',
    transition: {
      ease: 'easeIn',
    },
  },
  transition: {
    duration: 0.4,
  },
});

/* ⬇ Easing functions ⬇ */
/* https://easings.net */

/* eslint-disable */
export function easeOutBounce(x) {
  const n1 = 7.5625;
  const d1 = 2.75;

  if (x < 1 / d1) {
    return n1 * x * x;
  } else if (x < 2 / d1) {
    return n1 * (x -= 1.5 / d1) * x + 0.75;
  } else if (x < 2.5 / d1) {
    return n1 * (x -= 2.25 / d1) * x + 0.9375;
  } else {
    return n1 * (x -= 2.625 / d1) * x + 0.984375;
  }
}

export function easeOutElastic(x) {
  const c4 = (2 * Math.PI) / 3;

  return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
}
