import React from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useStateValue } from '../state';
import { urlFor } from '../lib/sanity';

const StyledBGContainer = styled(motion.div)`
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  img {
    object-fit: cover;
    object-position: center top;
  }
`;
// @TODO The position should be decided from Sanity

const fader = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { delay: 1.2 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

/**
 * Contrary to what you'd expect the image is NOT rendered as a background.
 * Turns out there's a known issue in chrome that will make backgrounds flicker
 * when there's 3D CSS animations going on, and there's no better workaround per now.
 * Google for more: "css animation background image flicker" and fix it when possible.
 */

const CustomBackground = () => {
  const [{ customBackground }] = useStateValue();

  return customBackground ? (
    <AnimatePresence exitBeforeEnter>
      <StyledBGContainer key={customBackground} {...fader}>
        <img alt="background" src={customBackground} style={{ height: '100vh', width: '100vw' }} />
      </StyledBGContainer>
    </AnimatePresence>
  ) : null;
};

export default CustomBackground;

export const resolveCustomBackground = page => {
  let customBGUrl = '';
  if (page._type === 'conditionalPage') {
    // TODO: @martin why are these different?
    customBGUrl = page.options[0].backgroundImage
      ? urlFor(page.options[0].backgroundImage).width(1500).quality(50).url()
      : '';
  } else {
    customBGUrl = page?.backgroundImage
      ? urlFor(page.backgroundImage).width(1500).quality(50).url()
      : '';
  }
  return customBGUrl;
};
