import React, { useState } from 'react';
import { object, bool } from 'prop-types';
import { useStateValue } from '../state';

import Form from './Form';
import Confetti from './Confetti';
import Layout from './PageLayout';
import Brandbox from './Brandbox';
import ProceedButton from './ProceedButton';

// FIXME: Wonky logic going on here ⬇
function resolveContent(data, score) {
  if (data._type === 'contentPage') return data;
  if (data._type === 'conditionalPage') {
    const output = data.options.filter(item => item.minScore <= score)[0];
    return output;
  }
  return null;
}

// eslint-disable-next-line no-unused-vars
const Page = ({ data, isLast, isFirst, blockStartButton }) => {
  const [confetti, setConfetti] = useState(data._type === 'conditionalPage');
  const [{ score, active }, dispatch] = useStateValue();
  const content = resolveContent(data, score);
  return (
    <Brandbox>
      <Layout
        blockLayout={content.blockLayout}
        text={content.body}
        image={content.image}
        isFirst={isFirst}
        active={active}
        blockStartButton={blockStartButton}
      />

      {content.showForm && <Form />}
      {content.showRestartBtn && (
        <ProceedButton label="restart" onClick={() => dispatch({ type: 'resetQuiz' })} />
      )}
      {confetti && content.showBling && (
        <Confetti wait={5000} timedExit={() => setConfetti(false)} />
      )}
    </Brandbox>
  );
};

Page.defaultProps = {
  isLast: false,
  isFirst: false,
};

Page.propTypes = {
  data: object.isRequired,
  isLast: bool,
  isFirst: bool,
};

export default Page;
