import * as Yup from 'yup';

// Get the corresponding validation object

export const validationObj = fields => {
  // TODO: Some complexities here. How do we deal with multiple languages.
  // Should we allow the form builder to dictate parts of the validation schema,
  // like number of characters etc.?

  // Iterate through the fields and return the validation object
  const validationTypes = {
    name: Yup.string()
      .min(3, 'Minst tre bokstaver')
      .matches(
        /^[a-zA-Z\u00C0-\u024F\s'-]*$/,
        'Kun bokstaver, bindestreker og apostrofer er tillatt',
      )
      .required('Obligatorisk felt!'),
    email: Yup.string().email('Ugyldig epostadresse').required('Obligatorisk felt!'),
    norPhone: Yup.string()
      .matches(/^(?:\+)?[0-9\s]*$/, 'Vennligst skriv inn et gyldig telefonnummer')
      .min(8, 'Minst 8 siffer')
      .required('Obligatorisk felt!'),
    consent: Yup.boolean().oneOf([true, false]),
  };

  const theFullVariation = {};

  fields.forEach(field => {
    if (field.validation) {
      // Return a validation. I need a reference for each validation type
      theFullVariation[field.Key] = validationTypes[field.validation];
    }
  });

  return Yup.object({
    ...theFullVariation,
  });
};
