import React from 'react';
import styled from 'styled-components';
import { StyledFormWrap } from '../Inputs';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { Card, Stack } from '@sanity/ui';
import ConfiguredForm from '../ConfiguredForm';

// import { validationObj } from './formValidationSchemas';
// import AlternativeButton from '../AlternativeButton';

const CustomStyledForm = styled(StyledFormWrap)`
  padding: 0;
  .customForm {
    padding: 0 2em 1em;
    padding: 0;
  }
  /* max-width: 560px; */
  // Staale - I changed this to 500px. Chesterton's fence.
  max-width: 500px;

  form label {
    font-size: 16px;
    margin: 10px 0 5px;
    &.checkbox-input {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 30px auto;
      input {
        // How do I style the colour of this checkbox?
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 1px;
      }
      .inputContainer {
      }
      p {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 500px) {
    .customForm {
      padding: 0;
    }
  }
  // TODO: This styling should have been in the input doc. But They didn't take.
  .textarea {
    min-height: 180px;
    font-size: 16px;
    font-family: inherit;
    font-style: italic;
  }
`;

export function LegacyForm({
  initialValues,
  formId,
  validationSchemaNEW,
  uiLabels,
  language,
  temporaryValidationSchema,
  postWhere,
  submitFailed,
  submitErrorMessage,
}) {
  // Your component code

  // function StandardFormComponent(props) {
  return (
    <CustomStyledForm>
      {!submitFailed ? (
        <Formik
          initialValues={initialValues[formId]}
          validationSchema={
            validationSchemaNEW(uiLabels[language]?.validation)[temporaryValidationSchema]
          }
          onSubmit={postWhere}
        >
          {({ isSubmitting }) => {
            // console.log({ isSubmitting });

            return (
              <Form>
                <ConfiguredForm isSubmitting={isSubmitting} formId={formId} />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <h3 style={{ color: 'darkred', padding: '0 1em' }}>{submitErrorMessage}</h3>
        // TODO: Add an alert here – firebase, logdna, email, SMS.
        // Just use Twillio
        // TODO: Also, this needs to be language specific
      )}
    </CustomStyledForm>
  );
}
