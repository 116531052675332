import React from 'react';
import { Card } from '@sanity/ui';
import { TextInput, Checkbox, TextArea, Dropdown } from './Inputs';
// Generate the form fields

export function createCustomFormFields(customForm) {
  const customInitialValues = {};
  let formFields = [];
  // Check that _rawFormBuilder is an array
  if (customForm._rawFormBuilder instanceof Array) {
    formFields = customForm._rawFormBuilder.map(f => {
      // We're not getting the actual input type from Sanity so we'll have to do some conversion

      // TODO: This is not ideal. We should define this somehow in the Sanity schema.
      let actualFieldType;
      switch (f.validation) {
        case 'name':
          actualFieldType = 'text';
          break;
        case 'email':
          actualFieldType = 'email';
          break;
        case 'norPhone':
          actualFieldType = 'tel';
          break;
        default:
          actualFieldType = 'text';
          break;
      }

      if (f.fieldType === 'string') {
        customInitialValues[f.Key] = '';
        return (
          <Card key={f._key}>
            {/* TODO: Get help from Adhip to sort the uncontrolled aspect */}
            {/* // TODO: In order for the styling to kick in, the type must be text. Where would I pull that from? */}
            {/* Update: I'm cheating by using the above switch statement. There are cleaner ways to go about it. */}
            <TextInput label={f.label} type={actualFieldType} name={f.Key} />
          </Card>
        );
      }
      if (f.fieldType === 'checkbox') {
        return (
          <Card key={f._key}>
            <Checkbox label={f.label} name={f.key}>
              {f.message}
            </Checkbox>
          </Card>
        );
      }
      if (f.fieldType === 'dropdown') {
        return (
          <Card key={f._key}>
            <Dropdown label={f.label} name={f.key} options={f.options} />
          </Card>
        );
      }

      // TODO: Create a text-field option here
      console.log('unknown field type');
      //   setCustomInitialValues(customInitialValues);
      return;
    });
    return { formFields, customInitialValues };
  }

  console.error('The custom form is not an array');
  return [];
}
