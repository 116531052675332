import { common } from './common';

export const tn = {
  fontLight: 'telenorlight',
  fontRegular: 'telenorregular',
  yngFontLight: 'gtpressuralight',
  yngFontRegular: '',

  fontBig: '2em',
  fontStandard: '1.2em',

  // YNG cascade
  yngFontSizeLarge: '30px',
  yngFontSizeMedium: '24px',
  yngFontSizeSmall: '18px',

  blueMain: '#19aaf8',
  blueDark: '#0078cf',
  colorCorrect: '#28a845',
  colorIncorrect: '#FF648C',
  colorCorrectYng: '#D1E5DB',
  colorIncorrectYng: '#FDEEF1',
  borderRadius: '3px',
};

export const telenorLight = {
  ...common,

  fonts: {
    light: 'telenorlight',
    default: 'telenorregular',
    copy: 'telenorlight',
  },
  logoAlignCenter: false,
  headerHover: 'black',
  colors: {
    background: '#fff',
    color: '#000',
    questionColor: '#fff',
    linkColor: '#19aaf8',
    primary: {
      default: '#19aaf8',
      darker: '#0078cf',
      lighter: '#19aaf8',
    },
    win: {
      default: '#28a845',
    },
    fail: {
      default: '#FF648C',
    },
  },
  card: {
    background: 'white',
    color: 'black',
    radius: '0 0 2em 0',
  },
  header: {
    mobileMenuFont: tn.fontLight,
    mobileNavDividerColor: tn.blueMain,
    mobileMenuFontColor: tn.blueMain,
  },
  frontPage: {
    buttonColor: tn.blueMain,
    buttonColorHover: tn.blueDark,
  },
  pagesStyles: {
    fontSizeHeading: '2.5em',
    fontSizeCopy: '18px',
    fontSizeButton: '1.2em',
    fontHeading: tn.fontLight,
    fontCopy: tn.fontLight,
    fontButton: tn.fontRegular,
  },
  buttons: {
    radius: '3px',
    hover: '#19aaf8',
    font: 'telenorregular',
    fontSize: '1.2em',
    padding: '0.8em 1.7em 1em',
    weight: 'normal',
  },
  questionStyle: {
    fontQuestion: tn.fontRegular,
    fontAnswer: tn.fontRegular,
    fontColor: 'red',
    buttonTextColor: 'white',
    buttonColor: tn.blueMain,
    buttonColorHover: tn.blueDark,
    fontSizeQuestion: '1.2em',
    fontSizeAnswer: '1.2em',
    answerButtonRadius: '3px',
    answerTextColor: 'white',
    answerTextWeight: 400,
  },
  feedbackStyle: {
    correctColor: tn.green,
    correctColorHover: tn.green,
    incorrectColor: tn.pink,
    incorrectColorHover: tn.pink,
    buttonColorSameAsFeedback: true,
    buttonFontWeight: '500',
    buttonFontColor: 'white',
    fontHeader: tn.fontRegular,
    fontSizeHeader: '2em',
    fontCopy: tn.fontRegular,
    fontSizeCopy: '1.5em',
    fontButton: tn.fontRegular,
    fontSizeButton: '1.2em',
  },

  feedbackPageStyle: {
    numberColor: 'black',
  },
  links: {
    // Experimental
    borderDotted: true,
    useBorder: true,
    borderOnHover: true,
    hoverColor: 'black',
  },

  strings: {
    ...common.strings,
    checkboxLabel: 'Jeg vil gjerne motta e-post når Telenor arrangerer konkurranser',
  },

  uiLabels: {
    norwegian: {
      form: {
        name: 'Navn',
        firstName: 'Fornavn',
        lastName: 'Etternavn',
        phone: 'Telefonnummer',
        email: 'E-post',
        consentMessage: 'Jeg vil gjerne motta e-post når Telenor arrangerer konkurranser',
        submit: 'Send inn',
        submitting: 'Sender…',
      },
    },
  },

  // Experimental branding from here on

  nav: `
    font-family: ${tn.fontLight};
    li:hover {
      color: #666;
    }
  `,

  mobileMenu: `
    li {
      border: 0;
      background: ${tn.blueMain};
    }
  `,

  standardPage: {
    basic: `
        font-family: ${tn.fontLight};
        `,

    h1: ` h1{
          font-size: ${tn.fontBig};
          font-weight: normal;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
        }`,

    h2: ` h2 {
          // font-family: ${tn.fontLight};
        }
        
        `,

    p: `p {
          font-size: ${tn.fontStandard};
          
        }`,
  },
  basicButton: `
      font-family: ${tn.fontRegular || 'sans-serif'};
      transition: 0.3s; /* If no themes have this, stick it in the component instead */
      box-shadow: 0;
      border: none;
      background: ${tn.blueMain};
      padding: 0.8em 1.7em 1em;
      border-radius: ${tn.borderRadius};
      font-size: 1.2em;
      font-weight: normal;
      color: white;
      border: none;
      
      &:hover {
        color: white;
        background: ${tn.blueDark};
      }
      `,

  question: `
    font-family: ${tn.fontRegular};
    font-size: ${tn.fontStandard};
    `,

  feedback: `
    font-family: ${tn.fontRegular};
    h1 {
      font-size: ${tn.fontBig}

    }
    p {
      font-size: 1.5em;
    }

    &.correct {
      h1 {
        color: ${tn.colorCorrect};
      }
      button {
        background: ${tn.colorCorrect};
      }
    }

    &.incorrect {
      h1 {
        color: ${tn.colorIncorrect};
      }
      button {
        background: ${tn.colorIncorrect};
      }
    }

  `,
  answers: `
    font-family: ${tn.fontRegular};
    background: ${tn.blueMain};
    color: white;
    border: 0;
    transition: 0;
    &:hover {
      background: ${tn.blueDark};
      // color: black;
    }
    `,
  form: `
    font-family: ${tn.fontLight};
  `,
};

export const telenorDark = {
  ...common,
  ...telenorLight,
  headerHover: 'silver',
  colors: {
    ...telenorLight.colors,
    background: '#0c0f27',
    color: '#fff',
  },

  strings: {
    ...common.strings,
    checkboxLabel: 'Jeg vil gjerne motta e-post når Telenor T-We arrangerer konkurranser',
  },

  // Experimental here

  standardPage: {
    ...telenorLight.standardPage,
    p: `p {
        font-size: 19px;
      }
    `,
  },
};

export const telenorYng = {
  ...common,
  ...telenorLight,

  nav: `
    font-family: ${tn.yngFontLight};
    li:hover {
      color: #666;
    }
  `,

  mobileMenu: `
    li {
      text-align: center;
      border: 0;
      background: black;
      font-size: ${tn.yngFontSizeMedium};
      a {
        color: white;
      }
    }
  `,

  standardPage: {
    basic: `
        font-family: ${tn.yngFontLight};
        `,

    h1: ` h1{
          font-size: ${tn.yngFontSizeLarge};
          font-weight: normal;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
        }`,

    h2: ` h2 {
          // font-family: ${tn.yngFontSizeMedium};
        }
        
        `,

    p: `p {
          font-size: ${tn.yngFontSizeSmall};
        }`,
  },

  basicButton: `
    font-family: ${tn.yngFontLight};
    background: black;
    color: white;
    padding: 0.8em 1.7em 1em;
    font-size: ${tn.yngFontSizeSmall};
    border: none;
    border: 1px solid black;
    border-radius: 3px;

  `,

  basicButtonHover: `
  
    background: white;
    color: black;
  `,

  question: `
    font-family: ${tn.yngFontLight};
    font-size: ${tn.yngFontSizeMedium};
    `,

  feedback: `
    font-family: ${tn.yngFontLight};
    h1 {
      font-size: ${tn.yngFontSizeLarge}

    }
    p {
      font-size: ${tn.yngFontSizeSmall}
    }

    button {
      border: none;
    }

    button {
      border: none;
    }

    &.correct {
 
      button {
        background: ${tn.colorCorrectYng};
        color: black;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.incorrect {
   
      button {
        background: ${tn.colorIncorrectYng};
        color: black;
          &:hover {
            text-decoration: underline;
        }
      }
    }

  `,

  answers: `
    font-family: ${tn.yngFontLight};
    font-size: ${tn.yngFontSizeSmall};
    background: black;
    color: white;
    border: 1px solid black;
    transition: 0;
    &:hover {
      background: white;
      color: black;
    }
  `,
  form: `
    font-family: ${tn.yngFontLight};
  `,
};
