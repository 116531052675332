import React from 'react';

import styled, { withTheme } from 'styled-components';

// We need these to be dynamic from the brand theme
/* I've only copied the Sanity-ui shadow, which I liked.  */

const Brandboxstyles = styled.div`
  /* background: white;
  box-shadow: 0 0 0 0.0625rem; */
  /* 
  --card-shadow-outline-color: rgba(134, 144, 160, 0.4);
   */
  /* Kristian */
  --card-shadow-outline-color: rgba(48, 51, 57/ 40%);

  --card-shadow-umbra-color: rgba(134, 144, 160, 0.2);
  --card-shadow-penumbra-color: rgba(134, 144, 160, 0.14);
  --card-shadow-ambient-color: rgba(134, 144, 160, 0.12);
  box-shadow: 0 0 0 0.0625rem var(--card-shadow-outline-color),
    0 0.4375rem 0.5rem -0.25rem var(--card-shadow-umbra-color),
    0 0.75rem 1.0625rem 0.125rem var(--card-shadow-penumbra-color),
    0 0.3125rem 1.375rem 0.25rem var(--card-shadow-ambient-color);
  /** From theme ⬇ */
  background-color: var(--card-bg-color);
  color: var(--card-fg-color);
  border-radius: var(--card-radius, 0);
  max-width: 50rem;

  // Staale: I added this 500px max to prevent weird behaviour on mid-sized screens
  // However, it only makes sense on vertically stacked image, not two column layouts.
  /* max-width: 500px; */

  margin: 2rem;
  padding: 2rem;

  @media (max-width: 500px) {
    margin: 1.5rem;
    padding: 1.5rem;
  }
  @media (max-width: 350px) {
    /* TODO: replace with dynamic media query */
    margin: 1rem;
  }
`;

function Brandbox({ children, theme }) {
  const {
    sanity: {
      brand: {
        card: { color, background, radius },
      },
    },
  } = theme;

  return (
    <Brandboxstyles
      style={{
        '--card-fg-color': color,
        '--card-bg-color': background,
        '--card-radius': radius,
      }}
    >
      {children}
    </Brandboxstyles>
  );
}

export default withTheme(Brandbox);
