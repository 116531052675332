import React from 'react';
import { string } from 'prop-types';
import { Box, Flex, Label } from '@sanity/ui';

const TwoCols = ({ label, children, totalPages, ...props }) => {
  const showTotalPages = totalPages && totalPages > 1;

  return (
    <Box width={0} style={{ width: '100%', padding: '10px 0' }} {...props}>
      <Flex justify="space-between" align="center">
        <Box>
          <Label>
            {/* {label} {totalPages && <span>Total: {totalPages && totalPages}</span>} */}
            {label}

            {showTotalPages && <span> (Total: {totalPages})</span>}
          </Label>
        </Box>
        <>{children}</>
      </Flex>
    </Box>
  );
};

TwoCols.propTypes = {
  label: string.isRequired,
};

export default TwoCols;
