import React from 'react';
import { array, string, oneOfType, oneOf } from 'prop-types';
import { Flex } from '@sanity/ui';
import styled, { withTheme } from 'styled-components';
import { useStateValue } from '../state';

const StyledButton = styled.button`
  /* font-family: ${p => p.theme?.sanity?.brand?.buttons?.font || 'sans-serif'};
  transition: ${p => p.theme?.sanity?.brand?.buttons?.transition || '0.3s'};
  box-shadow: 0;
  border: none;
  background: ${p => p.theme?.sanity?.brand?.frontPage?.buttonColor || '#333'};
  padding: ${p => p.theme?.sanity?.brand?.buttons?.padding || '1em'};
  border-radius: ${p => p.theme?.sanity?.brand?.buttons?.radius || '3px'};
  font-size: ${p => p.theme?.sanity?.brand?.buttons?.fontSize || '1.2em'};
  font-weight: ${p => p.theme?.sanity?.brand?.buttons?.weight || 'normal'};
  color: ${p => p.theme?.sanity?.brand?.frontPage?.buttonTextColor || 'white'};
  border: 1px solid ${p => p.theme?.sanity?.brand?.frontPage?.borderColor || 'none'};
  &:hover {
    color: ${p => p.theme?.sanity?.brand?.frontPage?.buttonTextColorHover || 'white'};
    background: ${p => p.theme?.sanity?.brand?.frontPage?.buttonColorHover || '#333'};
  } */
  /* &.outlineButton {
    border-color: white;
    background: none;
    outline: none;
    &:hover {
      /* TODO: @staale Create glinting animation
      Hover state define here:
      Glinting animation
      */
  /* background: rgba(255, 255, 255, 0.3); */
  /* } */

  /* &.dark { */
  /* color: black; */
  /* border-color: black; */
  /* } */
  /* } */

  ${p => p.theme?.sanity?.brand?.basicButton}

  &:hover {
    ${p => p.theme?.sanity?.brand?.basicButtonHover}
  }
`;

function ProceedButton({ theme, label, text, type, ...props }) {
  const [{ quiz }, dispatch] = useStateValue();
  const {
    sanity: {
      brand: {
        compensation,
        strings: { proceedBtnLabel, startBtnLabel, restartBtnLabel },
      },
    },
  } = theme;

  const labels = {
    start: startBtnLabel,
    restart: restartBtnLabel,
    proceed: proceedBtnLabel,
  };

  // This bit is deprecated – exists in a transition period

  if (quiz?.customStartButtonText && quiz?.customStartButtonText.length > 0) {
    // If start button text exists, replace start with that string, otherwise leave it
    labels.start = quiz.customStartButtonText;
  }

  // Switch -- if its start then check for start label, if its restart check for restart label, etc.

  let labelText = '';
  switch (label) {
    case 'start':
      labelText =
        quiz?.customUIlabels?.startButtonText && quiz?.customUIlabels?.startButtonText.length > 0
          ? quiz?.customUIlabels?.startButtonText
          : labels[label];
      break;
    case 'restart':
      labelText =
        quiz?.customUIlabels?.restartButtonText &&
        quiz?.customUIlabels?.restartButtonText.length > 0
          ? quiz?.customUIlabels?.restartButtonText
          : labels[label];
      break;
    case 'proceed':
      labelText =
        quiz?.customUIlabels?.proceedButtonText &&
        quiz?.customUIlabels?.proceedButtonText.length > 0
          ? quiz?.customUIlabels?.proceedButtonText
          : labels[label];
      break;
    default:
      labelText = labels[label];
      break;
  }

  function clickHandler() {
    // console.log(currentPage);
    dispatch({ type: 'moveForward' });
  }

  return (
    <Flex justify="left" marginY={4}>
      <StyledButton
        onClick={clickHandler}
        style={{
          cursor: 'pointer',
          width: type === 'wide' ? '100%' : 'auto',
          marginBottom: compensation?.frontPageBottomMargin || '',
        }}
        {...props}
      >
        {labelText || text}
        {/* {labels[label] || text} */}
      </StyledButton>
    </Flex>
  );
}

ProceedButton.defaultProps = {
  type: '',
  text: '',
  label: undefined,
};

ProceedButton.propTypes = {
  label: oneOf(['start', 'restart', 'proceed']),
  text: oneOfType([array, string]),
  type: string,
};

export default withTheme(ProceedButton);
