import React from 'react';
import { array, arrayOf, shape, bool } from 'prop-types';
import { motion } from 'framer-motion';
import { Flex } from '@sanity/ui';
import styled from 'styled-components';

import { easeOutElastic } from '../lib/anim';
import Answer from './Answer/Answer';

const containerAnim = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.2,
    },
  },
};

const item = {
  hidden: { opacity: 0, scale: 0.5 },
  show: { opacity: 1, scale: 1, transition: { ease: easeOutElastic, duration: 1 } },
};

const StyledBtnWrap = styled.div`
  width: 100%;
  button {
    width: 100%;
  }
`;

const MotionFragment = motion(StyledBtnWrap);

const AnimAnswers = ({ answers }) => {
  return (
    <motion.div variants={containerAnim} initial="hidden" animate="show">
      <Flex space={[3, 3, 4, 5]} direction="column" justify="center" align="stretch">
        {answers.map(({ _key, answerText, feedbackText, isCorrect = null }) => (
          <MotionFragment width="100%" key={_key} variants={item}>
            <Answer answerText={answerText} feedbackText={feedbackText} isCorrect={isCorrect} />
          </MotionFragment>
        ))}
      </Flex>
    </motion.div>
  );
};

AnimAnswers.propTypes = {
  answers: arrayOf(
    shape({
      answerText: array,
      feedbackText: array,
      isCorrect: bool,
    }),
  ).isRequired,
};

export default AnimAnswers;
