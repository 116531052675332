import React from 'react';
import styled from 'styled-components';
import { StyledFormWrap } from './Inputs';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Card, Stack } from '@sanity/ui';

import { validationObj } from './formValidationSchemas';
import AlternativeButton from '../AlternativeButton';

// This is not DRY as it's repeated in LegacyForm
const CustomStyledForm = styled(StyledFormWrap)`
  padding: 0;
  .customForm {
    padding: 0 2em 1em;
    padding: 0;
  }
  /* max-width: 560px; */
  // Staale - I changed this to 500px. Chesterton's fence.
  max-width: 500px;

  form label {
    font-size: 16px;
    margin: 10px 0 5px;
    &.checkbox-input {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 30px auto;
      input {
        // How do I style the colour of this checkbox?
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 1px;
      }
      .inputContainer {
      }
      p {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 500px) {
    .customForm {
      padding: 0;
    }
  }
  // TODO: This styling should have been in the input doc. But They didn't take.
  .textarea {
    min-height: 180px;
    font-size: 16px;
    font-family: inherit;
    font-style: italic;
  }
`;

const StyledStack = styled(Stack)`
  ${p => p.theme?.sanity?.brand?.form}/* .christmasStyle {
    margin: 1em -2em;
    padding: 2em;
    background: #0c0f27
      url(https://cdn.sanity.io/images/4kn9ntmr/production/70c455a8be62c334669e1bcbde1c0ac81e2917bd-1000x136.png)
      left bottom no-repeat;
    color: white;
    background-size: contain;
    padding-bottom: 7em;
    padding-top: 1px;
  }

  @media (max-width: 500px) {
    .christmasStyle {
      margin: 1em -24px;
      padding: 24px;
      padding-bottom: 6em;
      padding-top: 1px;
    }
  } */
`;

export default function CustomForm({ customInitialValues, customForm, postWhere, formFields }) {
  // Your component code

  return (
    <CustomStyledForm className="customForm" padding={4}>
      <Formik
        initialValues={customInitialValues}
        validationSchema={validationObj(customForm._rawFormBuilder)}
        onSubmit={postWhere}
      >
        {({ isSubmitting, values, errors, touched }) => (
          <Form>
            <StyledStack>
              {formFields}
              {/* <Field type="email" name="emailll" /> */}
              {/* <ErrorMessage name="emailll" component="div" /> */}
              {/* {console.log(initialValues)} */}

              <Card>
                <AlternativeButton disabled={isSubmitting} type="submit">
                  {/* // TODO: Add a label for sending to the button */}
                  {isSubmitting ? 'Sending …' : customForm?.submitButtonMessage}
                </AlternativeButton>
              </Card>
            </StyledStack>
            {/* {touched && <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>}
            {errors && <pre>Error:{JSON.stringify(errors, null, 2)}</pre>}
            {values && <pre>Values: {JSON.stringify(values, null, 2)}</pre>}
            {isSubmitting && <pre>Is submitting{JSON.stringify(isSubmitting, null, 2)}</pre>} */}
          </Form>
        )}
      </Formik>
    </CustomStyledForm>
  );
}
