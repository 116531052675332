import { common } from './common';

const vm = {
  dark: '#0a1e2c',
  darkHover: '#233542',
  lightHover: '#9bcbda',
  yellow: 'yellow',
  green: '#3697b5',
  greenDark: '#3088a3',
  red: '#f26a4b',
  redDark: '#d95f43',
  fat: 'VeneerTwo',
  regular: 'Open Sans',
  special: 'brandon-grotesque, sans-serif',
};

export const volmax = {
  ...common,

  card: {
    background: 'white',
    color: 'black',
    radius: '2px',
  },

  links: {
    hoverColor: vm.greenDark,
  },

  // fonts: {
  //   light: 'Source Sans',
  //   default: 'VeneerTwo',
  //   copy: 'Source Sans',
  // },
  colors: {
    background: vm.dark,
    color: '#fff',
    linkColor: vm.green,
    // linkColor
    // mobileMenuFont
    // mobileNavDividerColor
    // mobileMenuFontColor
  },

  header: {
    height: 40,
    logoHeight: 30,
    headerCompensate: 76,
  },

  nav: `
    font-family: ${vm.special};
    font-weight: 700;
    text-transform: uppercase;
    li:hover {
      color: ${vm.lightHover};
    }
  `,

  mobileMenu: `
    li {
      padding: 0;
      background: ${vm.darkHover};
      font-family: ${vm.special};
      text-transform: uppercase;
      a {
        color: white;
      }
    }
  `,

  standardPage: {
    basic: `
        font-family: ${vm.regular};
        font-weight: normal;
        `,

    h1: ` h1{
          
          font-family: ${vm.fat};
          font-size: 4em;
          font-weight: normal;
          line-height: 0.9;
          margin-bottom: 30px; // Stuff like this could be put in a variable too.
          color: ${vm.dark};
          padding-top: 0;
          margin-top: 12px;
          
          @media (max-width: 450px) {
            font-size: 54px;
          }
          @media (max-width: 380px) {
            font-size: 48px;
          }
        }`,

    h2: ` h2 {
        }
        
        `,

    p: `p {
          font-family: ${vm.regular};
          font-size: 18px;
          line-height: 1.4;
          color: ${vm.dark}
        }`,
  },

  basicButton: `
  
    font-weight: normal;
    background: ${vm.dark};
    color: white;
    transition: .3s;
    margin: 15px 0 30px;
    border-radius: 2px;
    border: 0;
    font-size: 22px;
    padding: 13px  30px;
    text-transform: uppercase;
    font-family: ${vm.special};
    font-weight: 700;
    font-style: normal;
  `,

  basicButtonHover: `
    background: ${vm.darkHover};
  
  `,

  question: `
    font-family: ${vm.regular};
    font-size: 20px;
    `,

  feedback: `
    font-family: ${vm.regular};
    h1 {
      font-family: ${vm.fat};
      font-weight: normal;
      font-size: 4em;
      padding-bottom: 0;
      margin-bottom: .3em;
      margin-top: 0;
      line-height: 0.9;
    }


    button {
      margin-bottom: 0;
      font-family: ${vm.special};
      // letter-spacing: 0.1em;

    }
    &.correct {
      h1 {
        color: ${vm.green};
      }
      button {
        background: ${vm.green};
      }
      button:hover {
        background: ${vm.greenDark};

      }
    }

    &.incorrect {
      h1 {
        color: ${vm.red};
      }
      button {
        background: ${vm.red};
      }
      button:hover {
        background: ${vm.redDark};
      }
    }
  `,
  answers: `
    font-size: 16px;
    background: ${vm.dark};
    border-radius: 2px;
    margin: 8px 0;
    -webkit-transition: .3s;
    transition: .3s;
    color: white;
    line-height: 1.5;
    // padding: 18px 36px;
    font-size: 18px;
    font-weight: 500;


    &:hover {
      background: ${vm.darkHover};
    }

    font-family: ${vm.regular};
  `,

  feedbackPageStyle: {
    weight: 'normal',
    numberColor: vm.green,
  },

  confettiColors: ['#0a1e2c', '#3697b5', '#c59368', '#f26a4b'],

  compensation: {
    frontPageBottomMargin: '-12px',
    submitButtonBottomMargin: '10px',
  },
  strings: {
    ...common.strings,
    proceedBtnLabel: 'Neste',
    checkboxLabel:
      'Ja, takk jeg vil gjerne motta nyheter, aktiviteter og invitasjoner fra Volmax via e-post og SMS. Jeg bekrefter å ha satt meg inn i Volmax’ betingelser for %%personvern||https://www.dealer.volvotrucks.no/volmax/no-no/tools/personvern-volmax.html%%.',
  },

  uiLabels: {
    norwegian: {
      form: {
        name: 'Navn',
        firstName: 'Fornavn',
        lastName: 'Etternavn',
        phone: 'Telefonnummer',
        email: 'E-post',
        consentMessage:
          'Ja, takk jeg vil gjerne motta nyheter, aktiviteter og invitasjoner fra Volmax via e-post og SMS. Jeg bekrefter å ha satt meg inn i Volmax’ betingelser for %%personvern||https://www.dealer.volvotrucks.no/volmax/no-no/tools/personvern-volmax.html%%.',
        submit: 'Send inn',
        submitting: 'Sender…',
      },
      validation: {
        fullName: 'Minst tre bokstaver',
        firstName: 'Minst tre bokstaver',
        lastName: 'Minst tre bokstaver',
        phoneNumber: 'Minst 8 siffer',
        postCode: 'Minst 4 tegn',
        email: 'Ugyldig e-postadresse',
        requiredField: 'Obligatorisk felt!',
      },
    },
  },
};
