/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
// FIXME: That is too many disables⬆
import React from 'react';
import { array } from 'prop-types';
import { Button, MenuButton, Menu, MenuItem } from '@sanity/ui';
import { FaBars as BurgerIcon } from 'react-icons/fa';
import styled from 'styled-components';

import { useStateValue } from '../state';

const StyledMenuButton = styled.ul`
  cursor: pointer;
  display: flex;
  /* margin-right: 5px; */
  li {
    margin-left: 1rem;
    list-style: none;
    transition: 0.3s;
    &:hover {
      /* color: rgba(0, 0, 0, 0.5); */
      color: ${props => props.theme?.sanity?.brand?.headerHover};
    }
  }

  ${props => props.theme?.sanity?.brand?.nav};
`;

// const isCentered = theme.sanity.brand.logoAlignCenter;

const NavMenu = ({ pages, theme }) => {
  const [, dispatch] = useStateValue();
  return pages.length > 10 ? (
    <MenuButton
      button={
        <Button
          mode="ghost"
          padding={3}
          icon={BurgerIcon}
          style={{ borderRadius: '50%', height: 40, width: 40 }}
        />
      }
      id="menu-button"
      menu={
        <Menu>
          {pages.map(({ _key, navTitle, body, blockLayout, image }) => (
            <MenuItem
              padding={5}
              key={_key}
              text={navTitle}
              onClick={() => {
                dispatch({ type: 'setShowModal', payload: { body, blockLayout, image } });
              }}
            />
          ))}
        </Menu>
      }
      placement="bottom-start"
    />
  ) : (
    <StyledMenuButton>
      {pages.map(({ _key, navTitle, body, blockLayout, image }) => (
        <li key={_key}>
          <a
            onClick={() => {
              dispatch({ type: 'setShowModal', payload: { body, blockLayout, image } });
            }}
          >
            {navTitle}
          </a>
        </li>
      ))}
    </StyledMenuButton>
  );
};

NavMenu.propTypes = {
  pages: array.isRequired,
};

export default NavMenu;
