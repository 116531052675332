export function getTempValidationSchema(formKey, formId) {
  switch (formKey) {
    case 'hr-textfield':
      return 'hrTextfield';
    case 'telenor-textinput':
      return 'telenorTextInput';
    case 'hr-omvendt':
      return 'hurtigrutenOmvendt';
    case 'volmax-standard':
      return 'volmax';
    default:
      return formId;
  }
}

export function getCheckboxLabel(quiz, tempCheckboxLabel) {
  return quiz?.customUIlabels?.consentMessageText &&
    quiz?.customUIlabels?.consentMessageText.length > 0
    ? quiz?.customUIlabels?.consentMessageText
    : tempCheckboxLabel;
}
