import React from 'react';
import { Switch } from '@sanity/ui';
import { string, bool, func } from 'prop-types';
import TwoCols from './TwoCols';

const BoolSwitch = ({ label, value, clickHandler }) => {
  return (
    <TwoCols label={label}>
      <Switch checked={value} onChange={clickHandler} />
    </TwoCols>
  );
};

BoolSwitch.propTypes = {
  label: string.isRequired,
  value: bool.isRequired,
  clickHandler: func.isRequired,
};

export default BoolSwitch;
