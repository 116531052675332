import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { createFire } from '../../lib/firestarter';

export async function postToApi(userInput) {
  // console.log({ userInput });

  try {
    const uuid = uuidv4();
    const _id = `userdata.${uuid}`;
    const doc = {
      _type: 'form.submit',
      _id,
      ...userInput,
    };
    console.log('posting');
    const res = await axios.post('/api/submitForm', doc);
    console.log('Posted ok');
    return res;
  } catch (error) {
    console.log('Error occurred', error);
    throw error;
  }
}

export async function postToFirebaseAPI(userInput) {
  // console.log('posting to Firebase');
  // const uuid = uuidv4();

  // console.log('Yup, we are on our way to firebase');

  // console.log({ userInput });

  // const res = await axios.post('/api/submitFormFirebase', userInput);

  // console.log({ res });
  // return null;
  // return res;

  const title = userInput.displayTitle ? userInput.displayTitle : 'Unknown ' + userInput.length;
  // console.log({ title });
  const fbResponse = await createFire(title, userInput, 'formData');
  // console.log({ fbResponse });
  return fbResponse;
}
