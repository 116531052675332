import * as Yup from 'yup';
export const validationSchemaNEW = unvalidatedLabels => {
  //TODO: We need a way to ensure that values are in place
  const labels = {
    fullName: 'Must be at least 3 characters',
    phoneNumber: 'Must have at least 8 digits',
    email: 'Please enter a valid email address',
    requiredField: 'This field is required!',
    ...unvalidatedLabels,
  };

  return {
    telenor: Yup.object({
      fullName: Yup.string().min(3, 'Minst tre bokstaver').required('Obligatorisk felt!'),
      phoneNumber: Yup.string().min(8, 'Minst 8 siffer').required('Obligatorisk felt!'),
      email: Yup.string().email('Ugyldig epostadresse').required('Obligatorisk felt!'),
      acceptedTerms: Yup.boolean().oneOf([true, false]),
    }),

    telenorTextInput: Yup.object({
      fullName: Yup.string().min(3, 'Minst tre bokstaver').required('Obligatorisk felt!'),
      phoneNumber: Yup.string().min(8, 'Minst 8 siffer').required('Obligatorisk felt!'),
      email: Yup.string().email('Ugyldig epostadresse').required('Obligatorisk felt!'),
      acceptedTerms: Yup.boolean().oneOf([true, false]),
      /* textinput: Yup.string()
      .min(2, 'Må være minst 2 bokstaver')
      .max(40, 'Maximum 40 tegn')
      .required('Dette feltet er påkrevd'), */
    }),

    // This is the only form that has a dynamic validation schema

    hurtigruten: Yup.object({
      fullName: Yup.string().min(3, labels.fullName).required(labels.requiredField),
      phoneNumber: Yup.string().min(8, labels.phoneNumber),
      email: Yup.string().email(labels.email).required(labels.requiredField),
      acceptedTerms: Yup.boolean().oneOf([true, false]),
    }),

    hurtigrutenOmvendt: Yup.object({
      textInput: Yup.string().min(1, labels.textInput).required(labels.requiredField),
      firstName: Yup.string().min(3, labels.firstName).required(labels.requiredField),
      lastName: Yup.string().min(3, labels.lastName).required(labels.requiredField),
      postCode: Yup.string().min(3, labels.postCode).required(labels.requiredField),
      // fullName: Yup.string().min(3, labels.fullName).required(labels.requiredField),
      phoneNumber: Yup.string().min(8, labels.phoneNumber).required(labels.requiredField),
      email: Yup.string().email(labels.email).required(labels.requiredField),
      acceptedTerms: Yup.boolean().oneOf([true, false]),
    }),

    hrTextfield: Yup.object({
      fullName: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('This field is required!'),
      phoneNumber: Yup.string()
        .min(8, 'Must have at least 8 digits')
        .required('Phone number is required!'),
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('This field is required!'),
      acceptedTerms: Yup.boolean().oneOf([true], 'This box must be checked!'),
      textfield: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .max(320, 'Maximum 320 characters')
        .required('This field is required!'),
    }),

    volmax: Yup.object({
      firstName: Yup.string().min(3, 'Minst tre bokstaver').required('Obligatorisk felt!'),
      lastName: Yup.string().min(3, 'Minst tre bokstaver').required('Obligatorisk felt!'),
      phoneNumber: Yup.string().min(8, 'Minst 8 siffer').required('Obligatorisk felt!'),
      email: Yup.string().email('Ugyldig epostadresse').required('Obligatorisk felt!'),
      acceptedTerms: Yup.boolean().oneOf([true, false]),
    }),
  };
};
