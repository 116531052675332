import React from 'react';

import App from './App';
import '../../../static/index.scss';

const index = () => {
  return <App />;
};

export default index;
