import React from 'react';
import styled from 'styled-components';
import { bool, object } from 'prop-types';
import { motion } from 'framer-motion';
import { bounceRotate } from '../lib/anim';

import Question from './Question/Question';
import Page from './Page';

// NOTE: Main learning point in this file: You can combo styled with motion!
const StyledMotion = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  will-change: transform;
  @media (max-width: ${p => p.theme?.sanity?.brand?.media?.xsmall || '786'}px) {
    padding-bottom: 5em;
  }
`;
/**
 * Needs ref forwarded from parent to measure height of content
 */
const Sequenced = ({ content, isLast, isFirst, blockStartButton, ...props }, ref) => {
  return (
    <StyledMotion ref={ref} {...props} {...bounceRotate(isFirst)}>
      {/* So, this statement differentiates between question pages and others */}
      {['contentPage', 'conditionalPage'].includes(content._type) ? (
        <Page
          data={content}
          blockStartButton={blockStartButton}
          isLast={isLast}
          isFirst={isFirst}
        />
      ) : (
        <Question
          text={content.text}
          answers={content.answers}
          image={content.image}
          video={content.video}
          type={content._type}
        />
      )}
    </StyledMotion>
  );
};

Sequenced.defaultProps = {
  isLast: false,
  isFirst: false,
};

Sequenced.propTypes = {
  content: object.isRequired,
  isLast: bool,
  isFirst: bool,
};

export default Sequenced;
