import React from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import { useMeasure } from 'react-use';

const FlipStyle = styled.div`
  max-width: 700px;
  min-width: 500px;
  display: block;
  ${p => p.calcHeight && `height: ${p.calcHeight}px;`}
  .flip-card {
    background-color: transparent;
    perspective: 1000px;
  }
  .flip-card-inner {
    position: relative;
    height: auto;
    width: 100%;
    text-align: center;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
  }
  .flip-card.flip .flip-card-inner {
    transform: rotateY(180deg);
  }
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    height: auto;
    width: 100%;
    backface-visibility: hidden;
    transform: rotateX(0deg);
  }
  .flip-card-front {
    color: black;
  }
  .flip-card-back {
    color: white;
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
  }

  @media (max-width: ${p => p.theme?.sanity?.brand?.media?.xsmall || '786'}px) {
    min-width: 100vw;
    margin: auto;
    .flip-card-back > div,
    .flip-card-front > div {
      margin: 1.5em;
    }
  }
  @media (max-width: ${p => p.theme?.sanity?.brand?.media?.tiny || '786'}px) {
    .flip-card-back > div,
    .flip-card-front > div {
      margin: 1em;
      padding: 1.5em;
    }
    .flip-card-back > div {
      font-size: 14px;
    }
  }
`;

// NOTE: This component expects exactly two top child nodes and will probably fail horribly if provided anything else.

const FlipCard = ({ children, isFlipped, ...props }) => {
  const [frontRef, { height: frontHeight }] = useMeasure();

  return (
    <FlipStyle {...props} calcHeight={frontHeight}>
      <div className={`flip-card ${isFlipped ? 'flip' : ''}`}>
        <div className="flip-card-inner">
          <div ref={frontRef} className="flip-card-front">
            {children[0]}
          </div>
          <div className="flip-card-back">{children[1]}</div>
        </div>
      </div>
    </FlipStyle>
  );
};

FlipCard.defaultProps = {
  isFlipped: false,
};

FlipCard.propTypes = {
  isFlipped: bool,
};

export default FlipCard;
