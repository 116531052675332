export const common = {
  /* We typically want to put everything we don't
  messing with the default theme under the brand-key */
  //   media: 'EvokedSet media styles',
  media: {
    tiny: 350,
    xsmall: 786,
    small: 900,
    medium: 1200,
    large: 1800,
    huge: 2400,
  },
  card: {
    background: 'white',
    color: 'black',
    radius: '5px',
  },
  confettiColors: ['#19aaf8', '#0c1026', '#a3deff', '#fff'],
  strings: {
    startBtnLabel: 'Start Quiz',
    restartBtnLabel: 'Start på nytt',
    proceedBtnLabel: 'Videre',
    submitBtnLabel: 'Send inn',
    submittingBtnLabel: 'Sender...',
    submitErrorMessage: 'Ooops! En feil oppstod, skjemaet ble ikke sendt inn.',
    checkboxLabel: 'Jeg vil gjerne motta e-post om konkurranser',
    inactiveQuizLabel: 'Denne aktiviteten er avsluttet.',
  },
  uiLabels: {
    norwegian: {
      validation: {
        fullName: 'Minst tre bokstaver',
        firstName: 'Minst tre bokstaver',
        lastName: 'Minst tre bokstaver',
        phoneNumber: 'Minst 8 siffer',
        email: 'Ugyldig e-postadresse',
        requiredField: 'Obligatorisk felt!',
      },
    },
  },
};
